import SignUp from "../pages/SignUp";
import SignIn from "../pages/SignIn";
import Profile from "../pages/CompleteProfile";
import Dashboard from "../pages/Dashboard";
import ForgetPassword from "../pages/ForgetPassword";
import { ProtectedRoute, DashboardRoute } from "./protected";
import ProfileInfo from "../pages/Dashboard/components/ProfileInfo";

export const ROUTES = [
  {
    path: "/sign-in",
    Element: <SignIn />,
  },
  {
    path: "/sign-up",
    Element: <SignUp />,
  },
  {
    path: "/profile",
    Element: (
      <ProtectedRoute>
        <Profile />
      </ProtectedRoute>
    ),
  },
  {
    path: "/dashboard/:patientId",
    Element: (
      <ProtectedRoute>
        <Dashboard />
      </ProtectedRoute>
    ),
  },
  {
    path: "/dashboard/:patientId/:defultTab",
    Element: (
      <ProtectedRoute>
        <Dashboard />
      </ProtectedRoute>
    ),
  },
  {
    path: "/dashboard/:patientId/:defultTab/:serviceId",
    Element: (
      <ProtectedRoute>
        <Dashboard />
      </ProtectedRoute>
    ),
  },
  {
    path: "/dashboard/:patientId/:defultTab/:serviceId/error",
    Element: (
      <ProtectedRoute>
        <Dashboard />
      </ProtectedRoute>
    ),
  },
  {
    path: "/dashboard/:patientId/:defultTab/:serviceId/succes",
    Element: (
      <ProtectedRoute>
        <Dashboard />
      </ProtectedRoute>
    ),
  },
  {
    path: "/dashboard",
    Element: <DashboardRoute />,
  },
  {
    path: "/account",
    Element: (
      <ProtectedRoute>
        <ProfileInfo />
      </ProtectedRoute>
    ),
  },
  {
    path: "/forget-password",
    Element: <ForgetPassword />,
  },
];
