export default class Utils {
  static isEmail = (value) => {
    var emailRegex = new RegExp(
      "^[a-zA-Z0-9._-]+@[a-zA-Z0-9.-]+.[a-zA-Z]{2,6}$"
    );
    return emailRegex.test(value);
  };

  static isMobile = (value) => {
    var mobileRegex = new RegExp("^(\\+98|0)\\d{2,3}\\d{8}$");
    return mobileRegex.test(value);
  };

  static isNumber = (value) => {
    var numberRegex = new RegExp("^[0-9]+$");
    return numberRegex.test(value);
  };

  static capitalize = (str) => {
    return str.charAt(0).toUpperCase() + str.slice(1);
  };

  static short = (str) => {
    return str.charAt(0).toUpperCase() + str.slice(1);
  };

  static shortString = (text = "", length = 40) => {
    return text.length > length ? `${text.substring(0, length)} ...` : text;
  };

  static convertToSlug = (text = "") => {
    return text.replace('/',' ').toLowerCase().split(" ").join("_");
  };
}
