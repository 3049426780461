import { useEffect, useState } from "react";
import { fetchData } from "../../../helpers/endpoints";
import "./Questionnaire.scss";
import FadeLoader from "react-spinners/FadeLoader";
import "react-accessible-accordion/dist/fancy-example.css";
import Utils from "../../../helpers/utils";
import { useMobile } from "../../../hooks";
import {
  Accordion,
  AccordionItem,
  AccordionItemHeading,
  AccordionItemButton,
  AccordionItemPanel,
} from "react-accessible-accordion";
import { CustomButton } from "../../../components/CustomButton";

const Questionnaire = ({ email, patientId }) => {
  const [forms, setForms] = useState([]);
  const [isLoading, setIsLoading] = useState(true);
  const isMobile = useMobile();
  useEffect(() => {
    async function fetchForms() {
      setIsLoading(true);
      var res = await fetchData("patient/" + patientId + "/form");
      if (res.status === 200) {
        setForms(res.data.date);
      }
      setIsLoading(false);
    }
    fetchForms();
  }, [patientId]);
  return (
    <div
      dir="ltr"
      className="forms d-flex flex-column justify-content-center align-items-center gap-3"
    >
      {!isLoading ? (
        forms.map((form) => {
          return form.children ? FormCard(form, patientId, isMobile) : null;
        })
      ) : (
        <FadeLoader color={"#18324e"} className="mt-3" />
      )}
    </div>
  );
};

const FormCard = (form, patientId, isMobile) => {
  return (
    <Accordion
      allowZeroExpanded={true}
      allowMultipleExpanded={true}
      // preExpanded={"0"}
    >
      <AccordionItem uuid={"0"}>
        <AccordionItemHeading>
          <AccordionItemButton>
            {" "}
            <div className="form-card__title">
              <h5>{form.title}</h5>
            </div>
            <div className="form-card__divider" />
            <div className="form-card__desc">
              <h6>{Utils.shortString(form.description)}</h6>
            </div>
          </AccordionItemButton>
        </AccordionItemHeading>
        <AccordionItemPanel>
          {ChildForm(form, patientId, isMobile)}
        </AccordionItemPanel>
      </AccordionItem>
    </Accordion>
  );
};
const ChildForm = (parent, patientId, isMobile) => {
  return (
    <div className=" d-flex flex-column gap-3 ">
      {parent.children.length <= 0
        ? ChildFormCard(parent, patientId, parent, isMobile)
        : parent.children.map((form) => {
            return ChildFormCard(form, patientId, parent, isMobile);
          })}
    </div>
  );
};
const ChildFormCard = (form, patientId, parent, isMobile) => {
  const active =
    form.priority > 0
      ? parent.children.filter((f) => {
          return f.priority < form.priority && !f.submission;
        }).length == 0
        ? true
        : false
      : true;
  return (
    <div className="form-card" id={form.code} style={{ height: "60px" }}>
      <div
        className="form-card__title d-flex flex-row gap-2 px-4"
        style={{ justifyContent: "flex-start", width: "75%" }}
      >
        {form.submission ? (
          <svg
            width="16"
            height="16"
            viewBox="0 0 16 16"
            fill="none"
            xmlns="http://www.w3.org/2000/svg"
            style={!isMobile ? { marginTop: "4px" } : {}}
          >
            <path
              d="M8 16.0001C3.58174 16.0001 0 12.4183 0 8.00006C0 3.58185 3.58179 6.10352e-05 8 6.10352e-05C12.4182 6.10352e-05 16 3.58185 16 8.00006C16 12.4183 12.4182 16.0001 8 16.0001ZM5.01709 7.80567C4.6729 7.48062 4.13041 7.49616 3.80536 7.84015C3.4803 8.18434 3.49585 8.72684 3.84004 9.05189L6.41136 11.4806C6.77907 11.8277 7.36601 11.7829 7.67652 11.3835L11.6766 6.24065C11.9672 5.86696 11.8998 5.32845 11.5261 5.03769C11.1525 4.74711 10.614 4.81447 10.3234 5.18816L6.90263 9.58627L5.01709 7.80567Z"
              fill="#248C00"
            />
          </svg>
        ) : (
          <svg
            width="16"
            height="16"
            viewBox="0 0 16 16"
            fill="none"
            xmlns="http://www.w3.org/2000/svg"
          >
            <path
              d="M8 16.0001C3.58174 16.0001 0 12.4183 0 8.00006C0 3.58185 3.58179 6.10352e-05 8 6.10352e-05C12.4182 6.10352e-05 16 3.58185 16 8.00006C16 12.4183 12.4182 16.0001 8 16.0001ZM5.01709 7.80567C4.6729 7.48062 4.13041 7.49616 3.80536 7.84015C3.4803 8.18434 3.49585 8.72684 3.84004 9.05189L6.41136 11.4806C6.77907 11.8277 7.36601 11.7829 7.67652 11.3835L11.6766 6.24065C11.9672 5.86696 11.8998 5.32845 11.5261 5.03769C11.1525 4.74711 10.614 4.81447 10.3234 5.18816L6.90263 9.58627L5.01709 7.80567Z"
              fill="#D1D1D1"
            />
          </svg>
        )}
        <h6 style={{ textAlign: "left" }}>{form.title}</h6>
      </div>
      {form.submission ? (
        <>
          <a
            href={
              "https://jotform.com/edit/" +
              form.submission.toString() +
              "?email=" +
              JSON.stringify({
                patientId: patientId,
                redirectUrl: `https://my.allbrainsclinic.com/dashboard/${patientId}/form`,
              })
            }
            target="_self"
          >
            <CustomButton title={"edit"} uiType="border" />
          </a>
        </>
      ) : (
        <a
          href={
            !active
              ? ""
              : "https://form.jotform.com/" +
                form.code +
                "?email=" +
                JSON.stringify({
                  patientId: patientId,
                  redirectUrl: `https://my.allbrainsclinic.com/dashboard/${patientId}/form`,
                })
          }
          target="_self"
          style={!active ? { cursor: "not-allowed" } : {}}
        >
          <button style={!active ? { backgroundColor: "#F2F2F2" } : {}}>
            start
          </button>
        </a>
      )}
    </div>
  );
};

export default Questionnaire;
