import React from "react";
import { Navigate } from "react-router-dom";
import Cookies from "js-cookie";

export const ProtectedRoute = ({ children }) => {
  const isAuthenticated = Cookies.get("access_token");

  return isAuthenticated ? children : <Navigate to="/sign-in" replace />;
};
export const DashboardRoute = ({ children }) => {
  const isAuthenticated = Cookies.get("access_token");
  const isPatientSet = localStorage.getItem("id");

  return isAuthenticated ? (
    isPatientSet ? (
      <Navigate to={"/dashboard/" + isPatientSet} />
    ) : (
      <Navigate to="/dashboard/0" />
    )
  ) : (
    <Navigate to="/sign-in" replace />
  );
};
