import axios from "axios";
import { API_BASE_URL } from "../constants/ApiConfig";
import Cookies from "js-cookie";

const fetchData = async (url) => {
  const response = await axios
    .get(API_BASE_URL + url, {
      headers: {
        "Content-Type": "application/json",
        Authorization: "Bearer " + Cookies.get("access_token"),
      },
    })
    .catch((reason) => {
      return reason.response.data.message;
    });
  return response;
};

const postData = async (url, data) => {
  const respons = await axios
    .post(API_BASE_URL + url, data, {
      headers: {
        "Content-Type": "application/json",
        Authorization: "Bearer " + Cookies.get("access_token"),
      },
    })
    .catch((reason) => {
      return reason.response.data.message;
    });
  return respons;
};
const postFormData = async (url, data) => {
  const respons = await axios
    .post(API_BASE_URL + url, data, {
      headers: {
        "Content-Type": "multipart/form-data",
        Authorization: "Bearer " + Cookies.get("access_token"),
        Accept: "application/json",
      },
    })
    .catch((reason) => {
      return reason.response.data.message;
    });
  return respons;
};
const putFormData = async (url, data) => {
  const respons = await axios
    .put(API_BASE_URL + url, data, {
      headers: {
        "Content-Type": "multipart/form-data",
        Authorization: "Bearer " + Cookies.get("access_token"),
        Accept: "application/json",
      },
    })
    .catch((reason) => {
      return reason.response.data.message;
    });
  return respons;
};
const putData = async (url, data) => {
  const respons = await axios
    .put(API_BASE_URL + url, data, {
      headers: {
        "Content-Type": "application/json",
        Authorization: "Bearer " + Cookies.get("access_token"),
      },
    })
    .catch((reason) => {
      return reason.response.data.message;
    });
  return respons;
};

const postWithoutToket = async (url, data) => {
  const respons = await axios
    .post(API_BASE_URL + url, data, {
      headers: {
        "Content-Type": "application/json",
      },
    })
    .catch((reason) => {
      return reason.response.data.message;
    });
  return respons;
};

const deleteData = async (url) => {
  const respons = await axios
    .delete(API_BASE_URL + url, {
      headers: {
        "Content-Type": "application/json",
        Authorization: "Bearer " + Cookies.get("access_token"),
      },
    })
    .catch((reason) => {
      return reason.response.data.message;
    });
  return respons;
};

export {
  fetchData,
  postData,
  postWithoutToket,
  putData,
  deleteData,
  postFormData,
  putFormData,
};
