import { useEffect, useState } from "react";
import {
  deleteData,
  fetchData,
  postData,
  putData,
  putFormData,
} from "../../../helpers/endpoints";
import { FileUploader } from "react-drag-drop-files";
import imageCompression from "browser-image-compression";
import { CustomInput } from "../../../components/CustomInput";
import { CustomButton } from "../../../components/CustomButton";
import { useAssets } from "../../../hooks";
import "@hassanmojab/react-modern-calendar-datepicker/lib/DatePicker.css";
import DatePicker from "@hassanmojab/react-modern-calendar-datepicker";
import Swal from "sweetalert2";
import Modal from "react-modal";
import FadeLoader from "react-spinners/FadeLoader";
import { useMobile } from "../../../hooks";
import { useNavigate } from "react-router-dom";
import "./PatientList.scss";
import "react-phone-number-input/style.css";
import PhoneInput, { isValidPhoneNumber } from "react-phone-number-input";

const PatientList = ({ user }) => {
  const navigator = useNavigate();
  const isMobile = useMobile();
  const [patients, setPatients] = useState([]);
  const [isDetails, setIsDetils] = useState(false);
  const [services, setServices] = useState("");
  const [patient, setPatient] = useState({
    firstName: "",
    lastName: "",
    birthday: "",
    sex: "",
    gender: "",
    relationship: "",
    careCard: "",
    emergencyContacts: [
      {
        relationship: "",
        country: "",
        phoneNumber: "",
        itemOpen: true,
      },
    ],
    teachers: [{ firstName: "", lastName: "", email: "", itemOpen: true }],
    serviceTypes: [],
  });
  const [options, setOption] = useState({});
  const [isLoading, setIsLoading] = useState(true);
  const [modalIsOpen, setIsOpen] = useState(false);
  const [modal2IsOpen, setIsOpen2] = useState(false);
  const [modal3IsOpen, setIsOpen3] = useState(false);
  const [isOpen, setOpen] = useState(false);
  const [img, setImg] = useState({});
  const [step, setStep] = useState(1);
  const { getFile } = useAssets();
  const customStyles = {
    overlay: {
      backgroundColor: "rgba(24, 50, 78, 0.9)",
      zIndex: 5,
    },
  };
  const uploadImage = async () => {
    setIsLoading(true);
    const options = {
      maxSizeMB: 1,
    };
    const compressedFile = await imageCompression(img, options);
    let formData = new FormData();
    formData.append("file", compressedFile);
    var res = await putFormData(
      "patient/" + patient._id + "/photo/upload",
      formData
    );
    if (res.status === 200) {
      setOpen(false);
      setPatient({
        firstName: "",
        lastName: "",
        birthday: "",
        sex: "",
        gender: "",
        relationship: "",
        careCard: "",
        emergencyContacts: [
          {
            relationship: "",
            country: "",
            phoneNumber: "",
            itemOpen: true,
          },
        ],
        teachers: [{ firstName: "", lastName: "", email: "", itemOpen: true }],
        serviceTypes: [],
      });
      fetchUser();
    } else {
      Swal.fire({
        title: "",
        text: res,
        icon: "error",
        confirmButtonText: "ok",
      });
    }
    setIsLoading(false);
  };

  async function fetchUser() {
    setIsLoading(true);
    var res = await fetchData("patient");
    if (res.status === 200) {
      setPatients(
        res.data.data.filter((p) => {
          if (p.deletedAt) {
            return false;
          } else return true;
        })
      );
    } else {
      Swal.fire({
        title: "",
        text: res,
        icon: "error",
        confirmButtonText: "ok",
      });
    }
    var res2 = await fetchData("config");
    if (res2.status === 200) {
      var canf = res2.data.data.profile;
      setOption({
        ...options,
        Gender: Object.values(canf.gender),
        Province: Object.keys(Object.values(canf.cities)[0]),
        CareerPosition: Object.values(canf.careers),
        Country: Object.keys(canf.cities),
        City: canf.cities,
        Sex: Object.values(canf.sex),
        Pronouns: Object.values(canf.pronoun),
        Prefix: Object.values(canf.prefix),
        Relationship: Object.values(res2.data.data.patient.relationship),
      });
    } else {
      Swal.fire({
        title: "",
        text: res2,
        icon: "error",
        confirmButtonText: "ok",
      });
    }

    var res3 = await fetchData("service/type");
    if (res3.status === 200) {
      setServices(res3.data.data);
    } else {
      Swal.fire({
        title: "",
        text: res3,
        icon: "error",
        confirmButtonText: "ok",
      });
    }

    setIsLoading(false);
  }
  useEffect(() => {
    fetchUser();
  }, []);
  const addPatient = async () => {
    setIsLoading(true);
    if (!patient.firstName) {
      Swal.fire({
        title: "",
        text: "First Name cannot be empty.",
        icon: "error",
        confirmButtonText: "ok",
      });
      setIsLoading(false);
      return;
    }
    if (!patient.lastName) {
      Swal.fire({
        title: "",
        text: "Last Name cannot be empty.",
        icon: "error",
        confirmButtonText: "ok",
      });
      setIsLoading(false);
      return;
    }
    if (!patient.birthday) {
      Swal.fire({
        title: "",
        text: "Date Of Birth cannot be empty.",
        icon: "error",
        confirmButtonText: "ok",
      });
      setIsLoading(false);
      return;
    }
    if (!patient.sex) {
      Swal.fire({
        title: "",
        text: "Sex cannot be empty.",
        icon: "error",
        confirmButtonText: "ok",
      });
      setIsLoading(false);
      return;
    }
    if (!patient.gender) {
      Swal.fire({
        title: "",
        text: "Gender Identity cannot be empty.",
        icon: "error",
        confirmButtonText: "ok",
      });
      setIsLoading(false);
      return;
    }
    if (!patient.careCard) {
      Swal.fire({
        title: "",
        text: "Care Card cannot be empty.",
        icon: "error",
        confirmButtonText: "ok",
      });
      setIsLoading(false);
      return;
    }
    if (patient.emergencyContacts.length === 0) {
      Swal.fire({
        title: "",
        text: "Emergnecy Contact cannot be empty.",
        icon: "error",
        confirmButtonText: "ok",
      });
      setIsLoading(false);
      return;
    }
    if (patient.teachers.length === 0) {
      Swal.fire({
        title: "",
        text: "Teachers/Care Provider Email cannot be empty.",
        icon: "error",
        confirmButtonText: "ok",
      });
      setIsLoading(false);
      return;
    }
    if (patient.serviceTypes.length === 0) {
      Swal.fire({
        title: "",
        text: "Services cannot be empty.",
        icon: "error",
        confirmButtonText: "ok",
      });
      setIsLoading(false);
      return;
    }
    var res = await postData("patient", patient);
    if (res.status === 201) {
      setPatient({ ...patient, _id: res.data.data._id });
      setIsOpen2(false);
      setOpen(true);
      await fetchUser();
    } else {
      Swal.fire({
        title: "",
        text: res,
        icon: "error",
        confirmButtonText: "ok",
      });
    }
    setIsLoading(false);
  };
  const editPatient = async () => {
    setIsLoading(true);
    if (!patient.firstName) {
      Swal.fire({
        title: "",
        text: "First Name cannot be empty.",
        icon: "error",
        confirmButtonText: "ok",
      });
      setIsLoading(false);
      return;
    }
    if (!patient.lastName) {
      Swal.fire({
        title: "",
        text: "Last Name cannot be empty.",
        icon: "error",
        confirmButtonText: "ok",
      });
      setIsLoading(false);
      return;
    }
    if (!patient.birthday) {
      Swal.fire({
        title: "",
        text: "Date Of Birth cannot be empty.",
        icon: "error",
        confirmButtonText: "ok",
      });
      setIsLoading(false);
      return;
    }
    if (!patient.sex) {
      Swal.fire({
        title: "",
        text: "Sex cannot be empty.",
        icon: "error",
        confirmButtonText: "ok",
      });
      setIsLoading(false);
      return;
    }
    if (!patient.gender) {
      Swal.fire({
        title: "",
        text: "Gender Identity cannot be empty.",
        icon: "error",
        confirmButtonText: "ok",
      });
      setIsLoading(false);
      return;
    }
    if (!patient.careCard || patient.careCard.length != 12) {
      Swal.fire({
        title: "",
        text: "Care Card must be 10 characters.",
        icon: "error",
        confirmButtonText: "ok",
      });
      setIsLoading(false);
      return;
    }
    if (patient.emergencyContacts.length === 0) {
      Swal.fire({
        title: "",
        text: "Emergnecy Contact cannot be empty.",
        icon: "error",
        confirmButtonText: "ok",
      });
      setIsLoading(false);
      return;
    }
    if (patient.teachers.length === 0) {
      Swal.fire({
        title: "",
        text: "Teachers/Care Provider Email cannot be empty.",
        icon: "error",
        confirmButtonText: "ok",
      });
      setIsLoading(false);
      return;
    }
    if (patient.serviceTypes.length === 0) {
      Swal.fire({
        title: "",
        text: "Services cannot be empty.",
        icon: "error",
        confirmButtonText: "ok",
      });
      setIsLoading(false);
      return;
    }
    var res = await putData("patient/" + patient._id, patient);
    if (res.status === 200) {
      setIsOpen2(false);
      setIsDetils(false);
      if (!patient.photo) {
        setPatient({ ...patient, _id: res.data.data._id });
        setOpen(true);
      } else
        setPatient({
          firstName: "",
          lastName: "",
          birthday: "",
          sex: "",
          gender: "",
          relationship: "",
          careCard: "",
          emergencyContacts: [
            {
              relationship: "",
              country: "",
              phoneNumber: "",
              itemOpen: true,
            },
          ],
          teachers: [
            { firstName: "", lastName: "", email: "", itemOpen: true },
          ],
          serviceTypes: [],
        });
      await fetchUser();
    } else {
      Swal.fire({
        title: "",
        text: res,
        icon: "error",
        confirmButtonText: "ok",
      });
    }
    setIsLoading(false);
  };
  const removePatient = async (id) => {
    var res = await deleteData("patient/" + id);
    if (res.status === 200) {
      fetchUser();
      setIsOpen3(false);
    } else {
      Swal.fire({
        title: "",
        text: res,
        icon: "error",
        confirmButtonText: "ok",
      });
      setIsOpen3(false);
    }
  };
  function insertBlankAfterEveryThreeCharacters(str) {
    var str = str.split(" ").join("").split("");
    var formatted = [];

    for (var i = 0; i < 4 && str.length; i++) {
      formatted.push(str.shift());
    }
    if (str.length) formatted.push(" ");
    while (str.length) {
      for (var i = 0; i < 3 && str.length; i++) {
        formatted.push(str.shift());
      }
      if (str.length) formatted.push(" ");
    }
    return formatted.join("");
  }
  return (
    <>
      <div dir="ltr" className="patientList">
        <h1 className="py-5">Patients List</h1>
        {!isLoading ? (
          !isMobile ? (
            <table className="patientList_table">
              <thead>
                <tr className="patientList_table_header py-3 px-3">
                  <th
                    style={
                      patients.length === 0
                        ? { width: "100%" }
                        : { width: "18%", paddingLeft: "1.5rem" }
                    }
                  >
                    Name
                  </th>
                  {patients.length !== 0 && (
                    <>
                      <th style={{ width: "18%", textAlign: "center" }}>
                        Care Card (PHN)
                      </th>
                      <th style={{ width: "18%", textAlign: "center" }}>
                        Relationship
                      </th>
                      <th style={{ width: "18%", textAlign: "center" }}>
                        Clinical Code
                      </th>

                      <th style={{ width: "4%", textAlign: "center" }} />
                      <th style={{ width: "4%", textAlign: "center" }} />
                    </>
                  )}
                </tr>
              </thead>
              {patients.map((p) => {
                return (
                  <tbody>
                    <tr
                      className={
                        p.clinicalCode
                          ? "patientList_table_row_active"
                          : "patientList_table_row"
                      }
                    >
                      <td
                        onClick={() => {
                          if (p.clinicalCode)
                            navigator("/dashboard/" + p._id + "/service");
                        }}
                        style={{ cursor: "pointer" }}
                      >
                        {" "}
                        {/* <img src={getFile("avatar")} alt="avatar" /> */}
                        <h5>{p.firstName + " " + p.lastName}</h5>
                      </td>
                      <td style={{ textAlign: "center" }}>
                        {insertBlankAfterEveryThreeCharacters(p.careCard)}
                      </td>
                      <td style={{ textAlign: "center" }}>{p.relationship}</td>
                      <td
                        style={
                          p.clinicalCode
                            ? { textAlign: "center" }
                            : { textAlign: "center", color: "red" }
                        }
                      >
                        {p.clinicalCode ?? "Not yet verified"}
                      </td>
                      <td
                        style={{
                          color: "#18324E",
                          paddingLeft: 0,
                          cursor: "pointer",
                          textAlign: "center",
                        }}
                        onClick={() => {
                          let emerjency = p.emergencyContacts;
                          emerjency.map((e, i) => {
                            emerjency[i] = { ...e, itemOpen: false };
                          });
                          setPatient({
                            ...p,
                            birthday: new Date(p.birthday),
                            emergencyContacts: emerjency,
                          });
                          setIsDetils(true);
                          setIsOpen2(true);
                        }}
                      >
                        Details
                      </td>
                      <td
                        style={{
                          color: "#BC2323",
                          paddingLeft: 0,
                          paddingRight: "1.5rem",
                          textAlign: "center",
                          cursor: "pointer",
                        }}
                        onClick={() => {
                          setPatient({ ...p, birthday: new Date(p.birthday) });
                          setIsOpen3(true);
                        }}
                      >
                        Remove
                      </td>
                    </tr>
                  </tbody>
                );
              })}
              {patients.length === 0 && (
                <tr>
                  <td
                    colSpan={6}
                    className="d-flex gap-2 flex-row justify-content-center align-items-center"
                  >
                    <svg
                      width="16"
                      height="16"
                      viewBox="0 0 16 16"
                      fill="none"
                      xmlns="http://www.w3.org/2000/svg"
                    >
                      <path
                        d="M8 0C5.87829 0 3.84335 0.842847 2.34316 2.34316C0.842963 3.84346 0 5.87822 0 8C0 10.1218 0.842847 12.1567 2.34316 13.6568C3.84346 15.157 5.87822 16 8 16C10.1218 16 12.1567 15.1572 13.6568 13.6568C15.157 12.1565 16 10.1218 16 8C15.9982 5.87888 15.1547 3.8449 13.6549 2.3451C12.1551 0.845258 10.1212 0.00182855 8 0ZM8.36078 12.2754C8.26474 12.3707 8.13525 12.4244 8 12.4253C7.86459 12.4251 7.7348 12.3713 7.63921 12.2754C7.54393 12.1794 7.49028 12.0499 7.48937 11.9146C7.48952 11.7792 7.54332 11.6495 7.63921 11.5539C7.8421 11.3636 8.1579 11.3636 8.36079 11.5539C8.45669 11.6495 8.51049 11.7792 8.51064 11.9146C8.50973 12.0499 8.45608 12.1794 8.36078 12.2754ZM8.51063 9.87213C8.51063 10.1542 8.28206 10.3828 8 10.3828C7.71794 10.3828 7.48937 10.1542 7.48937 9.87213V4.08495C7.48937 3.80289 7.71794 3.57432 8 3.57432C8.28206 3.57432 8.51063 3.80289 8.51063 4.08495V9.87213Z"
                        fill="#B13737"
                      />
                    </svg>

                    <h1
                      style={{
                        textAlign: "center",
                        fontSize: "20",
                        fontWeight: "bold",
                        marginLeft: "10px",
                      }}
                    >
                      There is no Patient Info set. Please add at least one
                      patient’s information to continue.
                    </h1>
                  </td>
                </tr>
              )}
              <tr className="patientList_table_footer  py-2">
                <td
                  style={{ cursor: "pointer" }}
                  onClick={() => setIsOpen(true)}
                >
                  {" "}
                  <svg
                    width="16"
                    height="16"
                    viewBox="0 0 16 16"
                    fill="none"
                    xmlns="http://www.w3.org/2000/svg"
                    style={{ marginRight: "5px", marginBottom: "3px" }}
                  >
                    <path
                      fill-rule="evenodd"
                      clip-rule="evenodd"
                      d="M15.2942 7.29424H8.70576V0.705756C8.70576 0.315785 8.38964 0 8 0C7.61003 0 7.29424 0.315785 7.29424 0.705756V7.29424H0.705756C0.315785 7.29424 0 7.61003 0 8C0 8.38997 0.315785 8.70576 0.705756 8.70576H7.29424V15.2942C7.29424 15.6839 7.61003 16 8 16C8.38964 16 8.70576 15.6839 8.70576 15.2942V8.70576H15.2942C15.6839 8.70576 16 8.38997 16 8C16 7.61003 15.6839 7.29424 15.2942 7.29424Z"
                      fill="#A1813A"
                    />
                  </svg>
                  {"   "}
                  Add Patient
                </td>
                {patients.length !== 0 && (
                  <>
                    <td />
                    <td />
                    <td />
                    <td />
                    <td />
                  </>
                )}
              </tr>
            </table>
          ) : (
            <table className="patientList_table">
              <thead>
                <tr className="patientList_table_header py-3 px-3">
                  <th style={{ width: "60%" }}>Name</th>
                  <th style={{ width: "20%" }} />
                  <th style={{ width: "20%" }} />
                </tr>
              </thead>
              {patients.map((p) => {
                return (
                  <tbody>
                    <tr className="patientList_table_row">
                      <td>
                        {" "}
                        {/* <img src={getFile("avatar")} alt="avatar" /> */}
                        <h5
                        // style={{ marginLeft: "2.5rem" }}
                        >
                          {p.firstName + " " + p.lastName}
                        </h5>
                      </td>
                      <td
                        style={{
                          color: "#18324E",
                          paddingLeft: 0,
                          cursor: "pointer",
                        }}
                        onClick={() => {
                          let emerjency = p.emergencyContacts;
                          emerjency.map((e, i) => {
                            emerjency[i] = { ...e, itemOpen: false };
                          });
                          setPatient({
                            ...p,
                            birthday: new Date(p.birthday),
                            emergencyContacts: emerjency,
                          });
                          setIsDetils(true);
                          setIsOpen2(true);
                        }}
                      >
                        Details
                      </td>
                      <td
                        style={{
                          color: "#BC2323",
                          paddingLeft: 0,
                          paddingRight: "1.5rem",
                        }}
                        onClick={() => {
                          setPatient({ ...p, birthday: new Date(p.birthday) });
                          setIsOpen3(true);
                        }}
                      >
                        Remove
                      </td>
                    </tr>
                  </tbody>
                );
              })}
              {patients.length === 0 && (
                <tr>
                  <td
                    colSpan={6}
                    className="d-flex gap-2 flex-row justify-content-center align-items-center"
                  >
                    <svg
                      width="80"
                      height="80"
                      viewBox="0 0 80 80"
                      fill="none"
                      xmlns="http://www.w3.org/2000/svg"
                    >
                      <path
                        d="M40 0C29.3915 0 19.2167 4.21423 11.7158 11.7158C4.21482 19.2173 0 29.3911 0 40C0 50.6089 4.21423 60.7833 11.7158 68.2842C19.2173 75.7852 29.3911 80 40 80C50.6089 80 60.7833 75.7858 68.2842 68.2842C75.7852 60.7827 80 50.6089 80 40C79.9909 29.3944 75.7735 19.2245 68.2745 11.7255C60.7753 4.22629 50.606 0.00914277 40 0ZM41.8039 61.3771C41.3237 61.8536 40.6763 62.1218 40 62.1264C39.3229 62.1256 38.674 61.8566 38.1961 61.3771C37.7196 60.8969 37.4514 60.2495 37.4468 59.5732C37.4476 58.8962 37.7166 58.2472 38.1961 57.7693C39.2105 56.8179 40.7895 56.8179 41.804 57.7693C42.2834 58.2472 42.5524 58.8962 42.5532 59.5732C42.5486 60.2495 42.2804 60.8969 41.8039 61.3771ZM42.5532 49.3606C42.5532 50.771 41.4103 51.9138 40 51.9138C38.5897 51.9138 37.4468 50.771 37.4468 49.3606V20.4247C37.4468 19.0144 38.5897 17.8716 40 17.8716C41.4103 17.8716 42.5532 19.0144 42.5532 20.4247V49.3606Z"
                        fill="#B13737"
                      />
                    </svg>

                    <h1
                      style={{
                        textAlign: "center",
                        fontSize: "20",
                        fontWeight: "bold",
                      }}
                    >
                      There is no Patient Info set. Please add at least one
                      patient’s information to continue.
                    </h1>
                  </td>
                </tr>
              )}
              <tr className="patientList_table_footer  py-2">
                <td
                  style={{ cursor: "pointer" }}
                  onClick={() => setIsOpen(true)}
                >
                  + Add Patient
                </td>
                <td />
                <td />
              </tr>
            </table>
          )
        ) : (
          <FadeLoader color={"#18324e"} />
        )}
      </div>
      <Modal
        isOpen={modalIsOpen}
        onRequestClose={() => {
          setIsOpen(false);
          setPatient({
            firstName: "",
            lastName: "",
            birthday: "",
            sex: "",
            gender: "",
            relationship: "",
            careCard: "",
            emergencyContacts: [
              {
                relationship: "",
                country: "",
                phoneNumber: "",
                itemOpen: true,
              },
            ],
            teachers: [
              { firstName: "", lastName: "", email: "", itemOpen: true },
            ],
            serviceTypes: [],
          });
          setIsDetils(false);
          setStep(1);
        }}
        style={customStyles}
        contentLabel="Example Modal"
        className="Modal1"
      >
        <div className="d-flex w-100 mt-4" style={{ gap: "90%" }}>
          <svg
            style={{ justifySelf: "left" }}
            width="24"
            height="18"
            viewBox="0 0 24 18"
            fill="none"
            xmlns="http://www.w3.org/2000/svg"
            onClick={() => {
              if (step == 1) {
                setPatient({
                  firstName: "",
                  lastName: "",
                  birthday: "",
                  sex: "",
                  gender: "",
                  relationship: "",
                  careCard: "",
                  emergencyContacts: [
                    {
                      relationship: "",
                      country: "",
                      phoneNumber: "",
                      itemOpen: true,
                    },
                  ],
                  teachers: [
                    { firstName: "", lastName: "", email: "", itemOpen: true },
                  ],
                  serviceTypes: [],
                });
                setIsOpen(false);
              } else {
                if (step == 3) setStep(1);
                else setStep(step - 1);
              }
            }}
          >
            <path
              d="M22.7996 8.39557H2.65334L10.0208 1.01421C10.2528 0.78219 10.2528 0.40603 10.0208 0.174013C9.7889 -0.0580043 9.41285 -0.0580043 9.18091 0.174013L0.781469 8.57595C0.665495 8.68473 0.599609 8.83691 0.599609 8.99605C0.599609 9.15519 0.665498 9.30737 0.781469 9.41615L9.18091 17.8181C9.28965 17.9341 9.44178 18 9.60088 18C9.75997 18 9.91211 17.9341 10.0208 17.8181C10.1368 17.7093 10.2027 17.5571 10.2027 17.398C10.2027 17.2388 10.1368 17.0867 10.0208 16.9779L2.65334 9.59653H22.7996C23.131 9.59653 23.3996 9.32781 23.3996 8.99639C23.3996 8.66498 23.131 8.39625 22.7996 8.39625V8.39557Z"
              fill="black"
            />
          </svg>
          <svg
            style={{ justifySelf: "right" }}
            width="24"
            height="24"
            viewBox="0 0 24 24"
            fill="none"
            xmlns="http://www.w3.org/2000/svg"
            onClick={() => {
              setPatient({
                firstName: "",
                lastName: "",
                birthday: "",
                sex: "",
                gender: "",
                relationship: "",
                careCard: "",
                emergencyContacts: [
                  {
                    relationship: "",
                    country: "",
                    phoneNumber: "",
                    itemOpen: true,
                  },
                ],
                teachers: [
                  { firstName: "", lastName: "", email: "", itemOpen: true },
                ],
                serviceTypes: [],
              });
              setIsOpen(false);
              setStep(1);
            }}
          >
            <path
              d="M0.418896 23.4842C1.01733 24.0813 1.93529 24.0813 2.53377 23.4842L11.951 14.089L21.3682 23.4842C21.9268 24.1213 22.8445 24.161 23.483 23.6434C24.1215 23.1257 24.1613 22.1704 23.6425 21.5334C23.6027 21.4937 23.5626 21.414 23.483 21.3743L14.0658 11.9791L23.483 2.58384C24.0815 2.02651 24.1215 1.07098 23.5629 0.473901C23.0043 -0.12314 22.0465 -0.163128 21.448 0.394202L11.951 9.86904L2.53385 0.473822C1.93541 -0.0835074 0.977621 -0.0437966 0.418974 0.553521C-0.0998546 1.11085 -0.0998546 2.02643 0.418974 2.58377L9.83616 11.979L0.418974 21.3742C-0.139658 21.9713 -0.139658 22.8871 0.418974 23.4842L0.418896 23.4842Z"
              fill="black"
            />
          </svg>
        </div>
        {step === 1 && (
          <div className=" d-flex flex-column justify-content-center align-items-center gap-4 pt-5 ">
            <h2>Who is this profile for?</h2>
            <div className="d-flex flex-row justify-content-center align-items-center gap-3">
              <CustomButton
                title={"It is for my dependent"}
                additionalClassNames="col-8 py-2"
                uiType={"border"}
                onClick={() => {
                  setStep(2);
                }}
              />
              <CustomButton
                title={"It is for myself"}
                uiType="primary2"
                additionalClassNames="col-8"
                onClick={() => {
                  setStep(3);
                  setPatient(user.profile);
                  setPatient({
                    ...patient,
                    firstName: user.firstName,
                    lastName: user.lastName,
                    relationship: "me",
                  });
                }}
              />
            </div>
          </div>
        )}
        {(step === 2 || step === 3) &&
          PersonalInfo(
            patient,
            setPatient,
            options,
            setStep,
            step,
            setIsLoading,
            isLoading,
            isMobile
          )}
        {step === 4 &&
          EmergnecyContact(
            patient,
            options,
            setPatient,
            setStep,
            getFile,
            isMobile
          )}
        {step === 5 &&
          EmailAddress(patient, setPatient, setStep, getFile, isMobile)}
        {step === 6 && Referral(patient, setPatient, setStep, isMobile)}
        {step === 7 &&
          Services(
            services,
            setStep,
            setIsOpen,
            setIsOpen2,
            patient,
            setPatient
          )}
      </Modal>
      <Modal
        isOpen={modal2IsOpen}
        onRequestClose={() => {
          setIsOpen2(false);
          setPatient({
            firstName: "",
            lastName: "",
            birthday: "",
            sex: "",
            gender: "",
            relationship: "",
            careCard: "",
            emergencyContacts: [
              {
                relationship: "",
                country: "",
                phoneNumber: "",
                itemOpen: true,
              },
            ],
            teachers: [
              { firstName: "", lastName: "", email: "", itemOpen: true },
            ],
            serviceTypes: [],
          });
          setIsDetils(false);
        }}
        style={customStyles}
        contentLabel="Example Modal"
        className="Modal2"
      >
        {Detile(
          patient,
          isDetails,
          addPatient,
          editPatient,
          isLoading,
          setIsOpen2,
          setIsOpen,
          setStep,
          services,
          setPatient
        )}
      </Modal>
      <Modal
        isOpen={modal3IsOpen}
        onRequestClose={() => {
          setIsOpen3(false);
          setPatient({
            firstName: "",
            lastName: "",
            birthday: "",
            sex: "",
            gender: "",
            relationship: "",
            careCard: "",
            emergencyContacts: [
              {
                relationship: "",
                country: "",
                phoneNumber: "",
                itemOpen: true,
              },
            ],
            teachers: [
              { firstName: "", lastName: "", email: "", itemOpen: true },
            ],
            serviceTypes: [],
          });
        }}
        style={customStyles}
        contentLabel="Example Modal"
        className="Modal1"
      >
        <div className=" d-flex flex-column justify-content-center align-items-center gap-5 pt-5 ">
          <h2>
            Are you sure to delete {patient.firstName + " " + patient.lastName}?
          </h2>
          <div className="d-flex flex-row justify-content-center align-items-center gap-3">
            <CustomButton
              title={"No"}
              uiType={"outlined2"}
              onClick={() => {
                setIsOpen3(false);
              }}
            />
            <CustomButton
              title={"Yes"}
              uiType="primary2"
              onClick={() => removePatient(patient._id)}
            />
          </div>
        </div>
      </Modal>
      <Modal
        isOpen={isOpen}
        onRequestClose={() => {
          setOpen(false);
          setPatient({
            firstName: "",
            lastName: "",
            birthday: "",
            sex: "",
            gender: "",
            relationship: "",
            careCard: "",
            emergencyContacts: [
              {
                relationship: "",
                country: "",
                phoneNumber: "",
                itemOpen: true,
              },
            ],
            teachers: [
              { firstName: "", lastName: "", email: "", itemOpen: true },
            ],
            serviceTypes: [],
          });
        }}
        style={customStyles}
        contentLabel="Example Modal"
        className="myModal"
      >
        <div className="d-flex flex-column justify-content-center gap-5 px-3">
          <h2
            className="my-4"
            style={{ textAlign: "center", fontSize: "20px", fontWeight: "700" }}
          >
            {" "}
            Please upload patient photo
          </h2>
          <FileUploader
            required
            handleChange={(file) => setImg(file)}
            name="file"
            types={["JPG", "PNG"]}
          />
          <div className="d-flex">
            <div className="col-4" />
            <CustomButton
              uiType="primary2"
              title={"Done"}
              additionalClassNames="col-4"
              onClick={uploadImage}
              loading={isLoading}
            />
          </div>
        </div>
      </Modal>
    </>
  );
};

const PersonalInfo = (
  patient,
  setPatient,
  options,
  setStep,
  step,
  setIsLoading,
  isLoading,
  isMobile
) => {
  function insertBlankAfterEveryThreeCharacters(str) {
    var str = str.split(" ").join("").split("");
    var formatted = [];

    for (var i = 0; i < 4 && str.length; i++) {
      formatted.push(str.shift());
    }
    if (str.length) formatted.push(" ");
    while (str.length) {
      for (var i = 0; i < 3 && str.length; i++) {
        formatted.push(str.shift());
      }
      if (str.length) formatted.push(" ");
    }
    return formatted.join("");
  }
  return (
    <>
      <div className=" info d-flex flex-column justify-content-center align-items-center gap-4 py-5 px-3">
        <h2>Enter patient’s Personal Information</h2>
        <CustomInput
          label={"First Name"}
          required
          fullWidth
          isRow={!isMobile}
          value={patient.firstName}
          onChange={(e) => {
            setPatient({ ...patient, firstName: e });
          }}
        />
        <CustomInput
          label={"Last Name"}
          required
          isRow={!isMobile}
          fullWidth
          value={patient.lastName}
          onChange={(e) => {
            setPatient({ ...patient, lastName: e });
          }}
        />
        <div
          className={`d-flex w-100 gap-3 ${
            isMobile ? "flex-column" : "flex-row align-items-center "
          } `}
          style={{ marginLeft: "10px" }}
        >
          <label className="col-lg-3" style={{ fontSize: "14px" }}>
            Date Of Birth
          </label>
          <DatePicker
            inputPlaceholder="Select a day"
            shouldHighlightWeekends
            calendarClassName="responsive-calendar"
            wrapperClassName="col-12 col-lg-8 ml-1"
            value={
              patient.birthday
                ? {
                    year: patient.birthday.getFullYear(),
                    month: patient.birthday.getMonth(),
                    day: patient.birthday.getDate(),
                  }
                : null
            }
            onChange={(e) => {
              setPatient({
                ...patient,
                birthday: new Date(e.year, e.month, e.day),
              });
            }}
          />
        </div>
        <CustomInput
          label={"Sex"}
          select
          options={options.Sex}
          required
          fullWidth
          isRow={!isMobile}
          value={patient.sex}
          onChange={(e) => {
            setPatient({ ...patient, sex: e });
          }}
        />
        <CustomInput
          label={"Gender Identity"}
          select
          options={options.Gender}
          required
          fullWidth
          isRow={!isMobile}
          value={patient.gender}
          onChange={(e) => {
            setPatient({ ...patient, gender: e });
          }}
        />
        {step === 2 ? (
          <CustomInput
            label={"Relationship"}
            select
            options={options.Relationship}
            required
            fullWidth
            isRow={!isMobile}
            value={patient.relationship}
            onChange={(e) => {
              setPatient({ ...patient, relationship: e });
            }}
          />
        ) : null}
        <CustomInput
          label={"Care Card(PHN)"}
          required
          fullWidth
          isRow={!isMobile}
          value={insertBlankAfterEveryThreeCharacters(patient.careCard)}
          onChange={(e) => {
            setPatient({ ...patient, careCard: e });
          }}
        />
        <CustomButton
          isLoading={isLoading}
          title={"Next"}
          uiType="primary"
          additionalClassNames="col-3"
          onClick={() => {
            if (!patient.firstName) {
              Swal.fire({
                title: "",
                text: "First Name cannot be empty.",
                icon: "error",
                confirmButtonText: "ok",
              });
              setIsLoading(false);
              return;
            }
            if (!patient.lastName) {
              Swal.fire({
                title: "",
                text: "Last Name cannot be empty.",
                icon: "error",
                confirmButtonText: "ok",
              });
              setIsLoading(false);
              return;
            }
            if (!patient.birthday) {
              Swal.fire({
                title: "",
                text: "Date Of Birth cannot be empty.",
                icon: "error",
                confirmButtonText: "ok",
              });
              setIsLoading(false);
              return;
            }
            if (!patient.sex) {
              Swal.fire({
                title: "",
                text: "Sex cannot be empty.",
                icon: "error",
                confirmButtonText: "ok",
              });
              setIsLoading(false);
              return;
            }
            if (!patient.gender) {
              Swal.fire({
                title: "",
                text: "Gender Identity cannot be empty.",
                icon: "error",
                confirmButtonText: "ok",
              });
              setIsLoading(false);
              return;
            }
            if (!patient.careCard || patient.careCard.length !== 12) {
              Swal.fire({
                title: "",
                text: "Care Card must be 10 characters.",
                icon: "error",
                confirmButtonText: "ok",
              });
              setIsLoading(false);
              return;
            }
            setStep(4);
          }}
        />
      </div>
    </>
  );
};
const EmergnecyContact = (
  patient,
  options,
  setPatient,
  setStep,
  getFile,
  isMobile
) => {
  return (
    <div className="info d-flex flex-column justify-content-center align-items-center gap-5 py-5 px-2">
      <h2>Enter patient’s Emergnecy Contact</h2>

      {patient.emergencyContacts.map((e, i) => {
        return (
          <>
            {e.itemOpen && (
              <>
                <div className="d-flex flex-row align-items-start pt-3 w-100">
                  <div className="col-10 d-flex flex-column gap-3 align-items-center">
                    <div
                      style={
                        !isMobile
                          ? {
                              alignItems: "center",
                              marginLeft: "20px",
                              marginRight: "15px",
                            }
                          : { marginLeft: "20px", marginRight: "15px" }
                      }
                      className={
                        !isMobile
                          ? "d-flex flex-row w-100 gap-2"
                          : "d-flex flex-column w-100 gap-2"
                      }
                    >
                      <span className="col-3 country">phone Number</span>
                      <PhoneInput
                        className="col-11 col-lg-8 my-1 mx-2 phon-input"
                        placeholder="Enter phone number"
                        defaultCountry="CA"
                        value={e.phoneNumber}
                        error={
                          e.phoneNumber
                            ? isValidPhoneNumber(e.phoneNumber)
                              ? undefined
                              : "Invalid phone number"
                            : "Phone number required"
                        }
                        onChange={(v) => {
                          let updated = patient.emergencyContacts;
                          updated[i].phoneNumber = v;
                          setPatient({
                            ...patient,
                            emergencyContacts: updated,
                          });
                        }}
                      />
                    </div>

                    <CustomInput
                      label={"Relationship"}
                      select
                      options={options.Relationship}
                      required
                      isRow={!isMobile}
                      fullWidth
                      value={e.relationship}
                      onChange={(v) => {
                        let updated = patient.emergencyContacts;
                        updated[i].relationship = v;
                        setPatient({
                          ...patient,
                          emergencyContacts: updated,
                        });
                      }}
                    />

                    <CustomButton
                      title={"add"}
                      uiType="primary2"
                      style={{ marginLeft: "-1.2rem", marginTop: "1rem" }}
                      additionalClassNames=" col-4 text-align-center"
                      onClick={() => {
                        if (!e.relationship) {
                          Swal.fire({
                            title: "",
                            text: "Relationship cannot be empty.",
                            icon: "error",
                            confirmButtonText: "ok",
                          });
                          return;
                        }
                        if (!e.phoneNumber) {
                          Swal.fire({
                            title: "",
                            text: "Phone Number cannot be empty.",
                            icon: "error",
                            confirmButtonText: "ok",
                          });
                          return;
                        }
                        if (!isValidPhoneNumber(e.phoneNumber)) {
                          Swal.fire({
                            title: "",
                            text: "Invalid Phone Number.",
                            icon: "error",
                            confirmButtonText: "ok",
                          });
                          return;
                        }
                        let updated = patient.emergencyContacts;
                        updated[i].itemOpen = false;
                        setPatient({
                          ...patient,
                          emergencyContacts: updated,
                        });
                      }}
                    />
                  </div>
                  <div className="col-1" />
                  {i !== 0 && (
                    <img
                      className="col-1 px-1 py-1"
                      src={getFile("minesIcon")}
                      style={
                        isMobile
                          ? {
                              cursor: "pointer",
                              width: "35px",
                              marginTop: "10px",
                            }
                          : { cursor: "pointer" }
                      }
                      onClick={() => {
                        setPatient({
                          ...patient,
                          emergencyContacts: patient.emergencyContacts.filter(
                            (e, index) => {
                              return index != i;
                            }
                          ),
                        });
                      }}
                    />
                  )}
                </div>
                <div className="add-patient_line" />
              </>
            )}
            {!e.itemOpen && (
              <>
                <div className="colaps-emerjency py-2 ">
                  <span>{e.relationship}</span>
                  <div
                    onClick={() => {
                      var updated = patient.emergencyContacts;
                      updated[i].itemOpen = true;
                      setPatient({
                        ...patient,
                        emergencyContacts: updated,
                      });
                    }}
                  >
                    {" "}
                    <svg
                      width="12"
                      height="13"
                      viewBox="0 0 12 13"
                      fill="none"
                      xmlns="http://www.w3.org/2000/svg"
                    >
                      <g opacity="0.5">
                        <path
                          d="M9.46769 5.56373L9.65168 5.74759L2.89883 12.5004H0V9.60174L6.75285 2.84857L9.28394 5.37966L9.46769 5.56373Z"
                          fill="black"
                        />
                        <path
                          d="M11.7497 3.64936L10.2641 5.13499L7.36523 2.23616L8.85086 0.750534C9.18503 0.416489 9.72881 0.416489 10.0629 0.750534L11.7498 2.43749C12.0837 2.77141 12.0837 3.31509 11.7497 3.64936H11.7497Z"
                          fill="black"
                        />
                      </g>
                    </svg>
                  </div>
                </div>
                <div className="add-patient_line" />
              </>
            )}
          </>
        );
      })}

      <div className="row align-items-center w-100">
        <img
          src={getFile("plusIcon")}
          style={{ cursor: "pointer", width: "55px" }}
          onClick={() => {
            setPatient({
              ...patient,
              emergencyContacts: [
                ...patient.emergencyContacts,
                {
                  relationship: "",
                  country: "",
                  phoneNumber: "",
                  itemOpen: true,
                },
              ],
            });
          }}
        />
      </div>
      <CustomButton
        title={"Next"}
        uiType="primary"
        onClick={() => {
          if (
            patient.emergencyContacts.length === 0 ||
            patient.emergencyContacts[0].itemOpen
          ) {
            Swal.fire({
              title: "",
              text: "Emergnecy Contact cannot be empty.",
              icon: "error",
              confirmButtonText: "ok",
            });
            return;
          }
          setStep(5);
        }}
      />
    </div>
  );
};

const EmailAddress = (patient, setPatient, setStep, getFile, isMobile) => {
  return (
    <div className="info d-flex flex-column justify-content-center align-items-center gap-5 py-5 px-2">
      <h2>Enter Teacher/Care Provider Email Address</h2>

      {patient.teachers.map((e, i) => {
        return (
          <>
            {e.itemOpen && (
              <>
                <div className="d-flex flex-row align-items-start pt-3 w-100">
                  <div className="col-10 d-flex flex-column gap-3 align-items-center">
                    <CustomInput
                      label={"First Name"}
                      required
                      isRow={!isMobile}
                      fullWidth
                      value={e.firstName}
                      onChange={(v) => {
                        let updated = patient.teachers;
                        updated[i].firstName = v;
                        setPatient({
                          ...patient,
                          teachers: updated,
                        });
                      }}
                    />
                    <CustomInput
                      label={"Last Name"}
                      required
                      isRow={!isMobile}
                      fullWidth
                      value={e.lastName}
                      onChange={(v) => {
                        let updated = patient.teachers;
                        updated[i].lastName = v;
                        setPatient({
                          ...patient,
                          teachers: updated,
                        });
                      }}
                    />
                    <CustomInput
                      label={"Email"}
                      required
                      isRow={!isMobile}
                      fullWidth
                      value={e.email}
                      onChange={(v) => {
                        let updated = patient.teachers;
                        updated[i].email = v;
                        setPatient({
                          ...patient,
                          teachers: updated,
                        });
                      }}
                    />

                    <CustomButton
                      title={"add"}
                      uiType="primary2"
                      style={{ marginLeft: "-1.2rem", marginTop: "1rem" }}
                      additionalClassNames=" col-4 text-align-center"
                      onClick={() => {
                        if (!e.firstName) {
                          Swal.fire({
                            title: "",
                            text: "First Name cannot be empty.",
                            icon: "error",
                            confirmButtonText: "ok",
                          });
                          return;
                        }
                        if (!e.lastName) {
                          Swal.fire({
                            title: "",
                            text: "Last Name cannot be empty.",
                            icon: "error",
                            confirmButtonText: "ok",
                          });
                          return;
                        }
                        if (!e.email) {
                          Swal.fire({
                            title: "",
                            text: "Email cannot be empty.",
                            icon: "error",
                            confirmButtonText: "ok",
                          });
                          return;
                        }
                        let updated = patient.teachers;
                        updated[i].itemOpen = false;
                        setPatient({
                          ...patient,
                          teachers: updated,
                        });
                      }}
                    />
                  </div>
                  <div className="col-1" />
                  {i !== 0 && (
                    <img
                      className="col-1 px-1 py-1"
                      src={getFile("minesIcon")}
                      style={
                        isMobile
                          ? {
                              cursor: "pointer",
                              width: "35px",
                              marginTop: "10px",
                            }
                          : { cursor: "pointer" }
                      }
                      onClick={() => {
                        setPatient({
                          ...patient,
                          teachers: patient.teachers.filter((e, index) => {
                            return index != i;
                          }),
                        });
                      }}
                    />
                  )}
                </div>
                <div className="add-patient_line" />
              </>
            )}
            {!e.itemOpen && (
              <>
                <div className="colaps-emerjency py-2 ">
                  <span>{e.firstName + " " + e.lastName}</span>
                  <div
                    onClick={() => {
                      var updated = patient.teachers;
                      updated[i].itemOpen = true;
                      setPatient({
                        ...patient,
                        teachers: updated,
                      });
                    }}
                  >
                    {" "}
                    <svg
                      width="12"
                      height="13"
                      viewBox="0 0 12 13"
                      fill="none"
                      xmlns="http://www.w3.org/2000/svg"
                    >
                      <g opacity="0.5">
                        <path
                          d="M9.46769 5.56373L9.65168 5.74759L2.89883 12.5004H0V9.60174L6.75285 2.84857L9.28394 5.37966L9.46769 5.56373Z"
                          fill="black"
                        />
                        <path
                          d="M11.7497 3.64936L10.2641 5.13499L7.36523 2.23616L8.85086 0.750534C9.18503 0.416489 9.72881 0.416489 10.0629 0.750534L11.7498 2.43749C12.0837 2.77141 12.0837 3.31509 11.7497 3.64936H11.7497Z"
                          fill="black"
                        />
                      </g>
                    </svg>
                  </div>
                </div>
                <div className="add-patient_line" />
              </>
            )}
          </>
        );
      })}
      <div className="row align-items-center w-100">
        <img
          src={getFile("plusIcon")}
          style={{ cursor: "pointer", width: "55px" }}
          onClick={() => {
            setPatient({
              ...patient,
              teachers: [
                ...patient.teachers,
                {
                  firstName: "",
                  lastName: "",
                  email: "",
                  itemOpen: true,
                },
              ],
            });
          }}
        />
      </div>
      <CustomButton
        title={"Next"}
        uiType="primary"
        onClick={() => {
          if (
            patient.teachers.length < 1 ||
            (patient.teachers.length === 1 && patient.teachers[0].itemOpen)
          ) {
            Swal.fire({
              title: "",
              text: "Teachers cannot be empty.",
              icon: "error",
              confirmButtonText: "ok",
            });
            return;
          }
          if (patient.referralBy == null) {
            setPatient({ ...patient, referralBy: {} });
          }
          setStep(6);
        }}
      />
    </div>
  );
};

const Detile = (
  patient,
  isDetails,
  addPatient,
  editPatient,
  isLoading,
  setIsOpen,
  setIsOpen2,
  setStep,
  services,
  setPatient
) => {
  function insertBlankAfterEveryThreeCharacters(str) {
    var str = str.split(" ").join("").split("");
    var formatted = [];

    for (var i = 0; i < 4 && str.length; i++) {
      formatted.push(str.shift());
    }
    if (str.length) formatted.push(" ");
    while (str.length) {
      for (var i = 0; i < 3 && str.length; i++) {
        formatted.push(str.shift());
      }
      if (str.length) formatted.push(" ");
    }
    return formatted.join("");
  }
  return (
    <div className="detile d-flex flex-column py-3 gap-5">
      <svg
        style={{
          marginLeft: "108%",
          marginBottom: "-25px",
          marginTop: "-25px",
        }}
        width="24"
        height="24"
        viewBox="0 0 24 24"
        fill="none"
        xmlns="http://www.w3.org/2000/svg"
        onClick={() => {
          setPatient({
            firstName: "",
            lastName: "",
            birthday: "",
            sex: "",
            gender: "",
            relationship: "",
            careCard: "",
            emergencyContacts: [
              {
                relationship: "",
                country: "",
                phoneNumber: "",
                itemOpen: true,
              },
            ],
            teachers: [
              { firstName: "", lastName: "", email: "", itemOpen: true },
            ],
            serviceTypes: [],
          });
          setIsOpen(false);
        }}
      >
        <path
          d="M0.418896 23.4842C1.01733 24.0813 1.93529 24.0813 2.53377 23.4842L11.951 14.089L21.3682 23.4842C21.9268 24.1213 22.8445 24.161 23.483 23.6434C24.1215 23.1257 24.1613 22.1704 23.6425 21.5334C23.6027 21.4937 23.5626 21.414 23.483 21.3743L14.0658 11.9791L23.483 2.58384C24.0815 2.02651 24.1215 1.07098 23.5629 0.473901C23.0043 -0.12314 22.0465 -0.163128 21.448 0.394202L11.951 9.86904L2.53385 0.473822C1.93541 -0.0835074 0.977621 -0.0437966 0.418974 0.553521C-0.0998546 1.11085 -0.0998546 2.02643 0.418974 2.58377L9.83616 11.979L0.418974 21.3742C-0.139658 21.9713 -0.139658 22.8871 0.418974 23.4842L0.418896 23.4842Z"
          fill="black"
        />
      </svg>
      <div>
        <table>
          <thead>
            <th colSpan={2}>
              Personal Information
              <svg
                onClick={() => {
                  setStep(2);
                  setIsOpen2(true);
                  setIsOpen(false);
                }}
                className="edit-icon"
                width="12"
                height="12"
                viewBox="0 0 12 12"
                fill="none"
                xmlns="http://www.w3.org/2000/svg"
              >
                <g opacity="0.5">
                  <path
                    d="M9.46769 5.06373L9.65168 5.24759L2.89883 12.0004H0V9.10174L6.75285 2.34857L9.28394 4.87966L9.46769 5.06373Z"
                    fill="#18324E"
                  />
                  <path
                    d="M11.7497 3.14936L10.2641 4.63499L7.36523 1.73616L8.85086 0.250534C9.18503 -0.0835112 9.72881 -0.0835112 10.0629 0.250534L11.7498 1.93749C12.0837 2.27141 12.0837 2.81509 11.7497 3.14936H11.7497Z"
                    fill="#18324E"
                  />
                </g>
              </svg>
            </th>
          </thead>

          <tbody className="personalTable">
            <tr>
              <td style={{ borderRight: "1px solid rgba(242, 242, 242, 1)" }}>
                First Name: {" " + patient.firstName}
              </td>
              <td>Last Name: {" " + patient.lastName}</td>
            </tr>
            <tr>
              <td style={{ borderRight: "1px solid rgba(242, 242, 242, 1)" }}>
                Date of Birth:
                {patient.birthday
                  ? " " +
                    patient.birthday.getFullYear() +
                    "/" +
                    patient.birthday.getMonth() +
                    "/" +
                    patient.birthday.getDate()
                  : " "}
              </td>
              <td>Sex:{" " + patient.sex} </td>
            </tr>
            <tr>
              <td style={{ borderRight: "1px solid rgba(242, 242, 242, 1)" }}>
                Gender: {" " + patient.gender}
              </td>
              <td>Relationship: {" " + patient.relationship} </td>
            </tr>
            <tr>
              <td style={{ borderRight: "1px solid rgba(242, 242, 242, 1)" }}>
                Care Card:{" "}
                {" " + insertBlankAfterEveryThreeCharacters(patient.careCard)}
              </td>
              <td />
            </tr>
          </tbody>
        </table>
      </div>

      <div>
        <table>
          <thead>
            <th colSpan={2}>
              Emergnecy Contact{" "}
              <svg
                onClick={() => {
                  setStep(4);
                  setIsOpen2(true);
                  setIsOpen(false);
                }}
                className="edit-icon"
                width="12"
                height="12"
                viewBox="0 0 12 12"
                fill="none"
                xmlns="http://www.w3.org/2000/svg"
              >
                <g opacity="0.5">
                  <path
                    d="M9.46769 5.06373L9.65168 5.24759L2.89883 12.0004H0V9.10174L6.75285 2.34857L9.28394 4.87966L9.46769 5.06373Z"
                    fill="#18324E"
                  />
                  <path
                    d="M11.7497 3.14936L10.2641 4.63499L7.36523 1.73616L8.85086 0.250534C9.18503 -0.0835112 9.72881 -0.0835112 10.0629 0.250534L11.7498 1.93749C12.0837 2.27141 12.0837 2.81509 11.7497 3.14936H11.7497Z"
                    fill="#18324E"
                  />
                </g>
              </svg>
            </th>
          </thead>
          <tbody>
            <tr>
              <td style={{ fontWeight: "bold" }}>phone Number</td>
              <td style={{ fontWeight: "bold" }}> Relationship</td>
            </tr>
            {patient.emergencyContacts.map((e) => {
              return (
                <tr>
                  <td>{e.phoneNumber}</td>
                  <td>{e.relationship}</td>
                </tr>
              );
            })}
          </tbody>
        </table>
      </div>

      <div>
        <table>
          <thead>
            <th colSpan={3}>
              Teacher/Care Provider Email Address{" "}
              <svg
                onClick={() => {
                  setStep(5);
                  setIsOpen2(true);
                  setIsOpen(false);
                }}
                className="edit-icon"
                width="12"
                height="12"
                viewBox="0 0 12 12"
                fill="none"
                xmlns="http://www.w3.org/2000/svg"
              >
                <g opacity="0.5">
                  <path
                    d="M9.46769 5.06373L9.65168 5.24759L2.89883 12.0004H0V9.10174L6.75285 2.34857L9.28394 4.87966L9.46769 5.06373Z"
                    fill="#18324E"
                  />
                  <path
                    d="M11.7497 3.14936L10.2641 4.63499L7.36523 1.73616L8.85086 0.250534C9.18503 -0.0835112 9.72881 -0.0835112 10.0629 0.250534L11.7498 1.93749C12.0837 2.27141 12.0837 2.81509 11.7497 3.14936H11.7497Z"
                    fill="#18324E"
                  />
                </g>
              </svg>
            </th>
          </thead>
          <tbody>
            <tr>
              <td style={{ fontWeight: "bold" }}>First Name</td>
              <td style={{ fontWeight: "bold" }}>Last Name</td>
              <td style={{ fontWeight: "bold" }}>Email</td>
            </tr>
            {patient.teachers.map((t) => {
              return (
                <tr>
                  <td>{t.firstName}</td>
                  <td>{t.lastName}</td>
                  <td>{t.email}</td>
                </tr>
              );
            })}
          </tbody>
        </table>
      </div>
      <div>
        <table>
          <thead>
            <th colSpan={4}>
              Referral Information
              <svg
                onClick={() => {
                  setStep(6);
                  setIsOpen2(true);
                  setIsOpen(false);
                }}
                className="edit-icon"
                width="12"
                height="12"
                viewBox="0 0 12 12"
                fill="none"
                xmlns="http://www.w3.org/2000/svg"
              >
                <g opacity="0.5">
                  <path
                    d="M9.46769 5.06373L9.65168 5.24759L2.89883 12.0004H0V9.10174L6.75285 2.34857L9.28394 4.87966L9.46769 5.06373Z"
                    fill="#18324E"
                  />
                  <path
                    d="M11.7497 3.14936L10.2641 4.63499L7.36523 1.73616L8.85086 0.250534C9.18503 -0.0835112 9.72881 -0.0835112 10.0629 0.250534L11.7498 1.93749C12.0837 2.27141 12.0837 2.81509 11.7497 3.14936H11.7497Z"
                    fill="#18324E"
                  />
                </g>
              </svg>
            </th>
          </thead>
          <tbody>
            <tr>
              <td style={{ fontWeight: "bold" }}>First Name</td>
              <td style={{ fontWeight: "bold" }}>Last Name</td>
              <td style={{ fontWeight: "bold" }}>Email</td>
              <td style={{ fontWeight: "bold" }}>Fax</td>
            </tr>

            {patient.referralBy && (
              <tr>
                <td>{patient.referralBy.firstName}</td>
                <td>{patient.referralBy.lastName}</td>
                <td>{patient.referralBy.email}</td>
                <td>{patient.referralBy.fax}</td>
              </tr>
            )}
          </tbody>
        </table>
      </div>
      <div>
        <table>
          <thead>
            <th colSpan={1}>
              Services
              <svg
                onClick={() => {
                  setStep(6);
                  setIsOpen2(true);
                  setIsOpen(false);
                }}
                className="edit-icon"
                width="12"
                height="12"
                viewBox="0 0 12 12"
                fill="none"
                xmlns="http://www.w3.org/2000/svg"
              >
                <g opacity="0.5">
                  <path
                    d="M9.46769 5.06373L9.65168 5.24759L2.89883 12.0004H0V9.10174L6.75285 2.34857L9.28394 4.87966L9.46769 5.06373Z"
                    fill="#18324E"
                  />
                  <path
                    d="M11.7497 3.14936L10.2641 4.63499L7.36523 1.73616L8.85086 0.250534C9.18503 -0.0835112 9.72881 -0.0835112 10.0629 0.250534L11.7498 1.93749C12.0837 2.27141 12.0837 2.81509 11.7497 3.14936H11.7497Z"
                    fill="#18324E"
                  />
                </g>
              </svg>
            </th>
          </thead>
          <tbody>
            {patient.serviceTypes.map((s) => {
              var type = {};
              services.map((ty) => {
                var temp = ty.children.filter((t) => {
                  return s === t._id;
                });
                if (temp.length > 0) {
                  type = temp[0];
                }
              });
              return (
                <tr>
                  <td>{type.title}</td>
                </tr>
              );
            })}
          </tbody>
        </table>
      </div>
      <CustomButton
        title={"Confirm"}
        additionalClassNames="col-3"
        loading={isLoading}
        onClick={() => {
          if (!isDetails) {
            addPatient();
          } else {
            editPatient();
          }
        }}
      />
    </div>
  );
};

const Services = (
  services,
  setStep,
  setIsOpen,
  setIsOpen2,
  patient,
  setPatient
) => {
  return (
    <div className="d-flex flex-column">
      <h2
        style={{
          fontSize: "35px",
          fontWeight: " 700",
          lineHeight: "42px",
        }}
      >
        Choose at least one service
      </h2>

      {services.map((s) => {
        return (
          <div className="services d-flex flex-column gap-3">
            <h5>{s.title}</h5>
            <div className="d-flex flex-column gap-3">
              {s.children.map((c) => {
                return (
                  <label className="radio">
                    <input
                      className="radio-input"
                      type="checkbox"
                      checked={patient.serviceTypes.indexOf(c._id) !== -1}
                      onChange={(e) => {
                        var index = patient.serviceTypes.indexOf(c._id);
                        if (index === -1) {
                          setPatient({
                            ...patient,
                            serviceTypes: [...patient.serviceTypes, c._id],
                          });
                        } else {
                          setPatient({
                            ...patient,
                            serviceTypes: patient.serviceTypes.splice(index, 1),
                          });
                        }
                      }}
                    />
                    <span className="custom-radio" />
                    {c.title}
                  </label>
                );
              })}
            </div>
          </div>
        );
      })}

      <div
        style={{ justifyContent: "center" }}
        className="d-flex flex-row w-100 my-5 py-3"
      >
        <CustomButton
          title={"Next"}
          uiType="primary"
          additionalClassNames="col-3"
          onClick={() => {
            if (patient.serviceTypes.length < 1) {
              Swal.fire({
                title: "",
                text: "Services cannot be empty.",
                icon: "error",
                confirmButtonText: "ok",
              });
              return;
            }
            setIsOpen(false);
            setIsOpen2(true);
            setStep(1);
          }}
        />
      </div>
    </div>
  );
};

const Referral = (patient, setPatient, setStep, isMobile) => {
  return (
    <div className="info d-flex flex-column justify-content-center align-items-center gap-5 py-5 px-2">
      <h2>Enter your Referring Doctor’s Information</h2>
      <div className="col-12 d-flex flex-column gap-3 align-items-center">
        <CustomInput
          label={"First Name"}
          required
          isRow={!isMobile}
          fullWidth
          value={patient.referralBy ? patient.referralBy.firstName : ""}
          onChange={(v) => {
            let updated = patient.referralBy;
            updated.firstName = v;
            setPatient({
              ...patient,
              referralBy: updated,
            });
          }}
        />
        <CustomInput
          label={"Last Name"}
          required
          isRow={!isMobile}
          fullWidth
          value={patient.referralBy ? patient.referralBy.lastName : ""}
          onChange={(v) => {
            let updated = patient.referralBy;
            updated.lastName = v;
            setPatient({
              ...patient,
              referralBy: updated,
            });
          }}
        />
        <CustomInput
          label={"Email"}
          isRow={!isMobile}
          fullWidth
          value={patient.referralBy ? patient.referralBy.email : ""}
          onChange={(v) => {
            let updated = patient.referralBy;
            updated.email = v;
            setPatient({
              ...patient,
              referralBy: updated,
            });
          }}
        />
        <CustomInput
          label={"Fax"}
          isRow={!isMobile}
          fullWidth
          value={patient.referralBy ? patient.referralBy.fax : ""}
          onChange={(v) => {
            let updated = patient.referralBy;
            updated.fax = v;
            setPatient({
              ...patient,
              referralBy: updated,
            });
          }}
        />
        <div>
          <input
            type="checkbox"
            onChange={(e) => {
              setPatient({
                ...patient,
                referralBy: {
                  ...patient.referralBy,
                  checked: e.target.checked,
                },
              });
            }}
          />
          <label style={{ marginLeft: "7px" }}>
            I don’t have a referring doctor
          </label>
        </div>
      </div>

      <CustomButton
        title={"Next"}
        uiType="primary"
        onClick={() => {
          if (patient.referralBy.checked) {
            var temp = patient;
            delete temp.referralBy;
            setPatient(temp);
            setStep(7);
          } else {
            if (patient.referralBy.firstName == "") {
              Swal.fire({
                title: "",
                text: "First Name cannot be empty.",
                icon: "error",
                confirmButtonText: "ok",
              });
              return;
            }
            if (patient.referralBy.lastName == "") {
              Swal.fire({
                title: "",
                text: "Last Name cannot be empty.",
                icon: "error",
                confirmButtonText: "ok",
              });
              return;
            }
            setStep(7);
          }
        }}
      />
    </div>
  );
};

export default PatientList;
