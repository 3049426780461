import React from "react";
import GoogleMapReact from "google-map-react";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faLocationDot } from "@fortawesome/free-solid-svg-icons";
import "./style.scss";

const LocationPin = ({ text }) => (
  <div className="pin">
    <FontAwesomeIcon icon={faLocationDot} color={"red"} size="2xl" />
    <p className="pin-text">{text}</p>
  </div>
);

const Map = ({ location, zoomLevel, isFooter }) => (
  <div className="map">
    <div className={`google-map${isFooter ? "-footer" : ""}`}>
      <GoogleMapReact
        bootstrapURLKeys={{ key: "AIzaSyAOh6eF5gMOPkji7kWTteb1eccdK64gU1I" }}
        defaultCenter={location}
        defaultZoom={zoomLevel}
      >
        <LocationPin lat={location.lat} lng={location.lng} />
      </GoogleMapReact>
    </div>
  </div>
);

export default Map;
