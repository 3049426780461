import { useEffect, useState } from "react";
import { fetchData } from "../../../helpers/endpoints";
import { useMobile } from "../../../hooks";
import "./Appointment.scss";
import FadeLoader from "react-spinners/FadeLoader";
import { CustomButton } from "../../../components/CustomButton";
import Swal from "sweetalert2";
import { CustomInput } from "../../../components/CustomInput";
import Modal from "react-modal";
import "@hassanmojab/react-modern-calendar-datepicker/lib/DatePicker.css";
import { Calendar } from "@hassanmojab/react-modern-calendar-datepicker";

const Appointments = ({ patientId, name, mrn, email }) => {
  const [appointments, setAppointments] = useState([]);
  const [services, setServices] = useState([]);
  const [isLoading, setIsLoading] = useState(true);
  const [service, setService] = useState({ url: "" });
  const [isOpen, setIsOpen] = useState(false);
  const [title, setTitle] = useState([]);
  const [selectedDays, setSelectedDays] = useState([]);
  const isMobile = useMobile();

  async function fetchAppointments() {
    setIsLoading(true);
    var res = await fetchData("patient/" + patientId + "/appointment");
    if (res.status === 200) {
      var tempelate = res.data.date;
      var i = 0;
      for (var appointment of res.data.date) {
        var title = await fetchtitle(appointment, i);
        tempelate[i] = { ...tempelate[i], title: title };
        let d = new Date(appointment.startedAt.substr(0, 10));
        let temp = selectedDays;
        temp[i] = {
          year: d.getFullYear(),
          month: d.getMonth(),
          day: d.getDate(),
        };
        i++;
        setSelectedDays(temp);
      }
      setAppointments(tempelate);
    }

    var res2 = await fetchData(
      "patient/" + patientId + "/appointment/event/index"
    );

    if (res2.status === 200) {
      setServices(res2.data.date);
    } else {
      Swal.fire({
        title: "",
        text: res2.message,
        icon: "error",
        confirmButtonText: "ok",
      });
    }
    setIsLoading(false);
  }
  async function fetchtitle(appointment, i) {
    if (appointment.eventId) {
      var res = await fetchData(
        "patient/" + patientId + "/appointment/event/" + appointment.eventId
      );
      if (res.status == 200) {
        return res.data.date.title;
      }
    }
  }
  useEffect(() => {
    if (patientId != 0) fetchAppointments();
  }, [patientId]);

  const customStyles = {
    overlay: {
      backgroundColor: "rgba(24, 50, 78, 0.9)",
      zIndex: "11",
    },
    content: {
      height: "55vh",
    },
  };
  return (
    <>
      <div
        dir="ltr"
        className="appointments d-flex flex-column gap-5 py-4 px-3"
      >
        <div className="appointments d-flex flex-column gap-2">
          <h1>Current Appointments</h1>
        </div>

        {isLoading ? (
          <FadeLoader color={"#18324e"} className="mt-3" />
        ) : (
          <div className="appointments_row px-2 d-flex  ">
            <div className="col-lg-8 col-12">
              <>
                {appointments.map((a, i) => {
                  return Appointment(a, fetchtitle, i);
                })}
              </>
            </div>
            {
              <div
                className="col-lg-4 col-12 px-3"
                style={
                  isMobile
                    ? {
                        paddingTop: "3rem",
                        marginLeft: "-3rem",
                      }
                    : {}
                }
              >
                {
                  <Calendar
                    value={selectedDays}
                    shouldHighlightWeekends
                    colorPrimary="#F01791"
                    onChange={() => setSelectedDays(selectedDays)}
                    onRequestClose={() => {}}
                  />
                }
              </div>
            }
          </div>
        )}
      </div>
      <Modal
        isOpen={isOpen}
        onRequestClose={() => setIsOpen(false)}
        style={customStyles}
        contentLabel="Example Modal"
        className="myModal"
      >
        <div className="d-flex flex-column justify-content-center gap-4 px-3">
          <div className="d-flex flex-column  justify-content-center align-items-center w-100 gap-5 px-2">
            <h1
              style={{
                fontSize: "30px",
                fontWeight: "700",
                textAlign: "center",
              }}
            >
              Choose a Service
            </h1>

            <CustomInput
              select
              options={services.map((s) => {
                return s.title;
              })}
              onChange={(e) => {
                setService(
                  services.filter((s) => {
                    return s.title === e;
                  })[0]
                );
                console.log(service);
              }}
              fullWidth
            />
            <a
              href={
                service.url + "?name=" + name + "&a1=" + mrn + "&email=" + email
              }
              // target="_blank"
              className="col-8"
            >
              <CustomButton
                title={"Viewing Appointment Times"}
                uiType="primary2"
                fullWidth
              />
            </a>
          </div>
        </div>
      </Modal>
    </>
  );
};

const Appointment = (appointment, fetchtitle, i) => {
  return (
    <div key={appointment._id} className="col-11 d-flex flex-row ">
      <div className="appointment col-9 d-flex flex-column px-3 py-4 gap-2">
        <h2>{appointment.title}</h2>
        <h3>
          Starts at {appointment.startedAt.substr(11, 5)} Finishes at{" "}
          {appointment.finishedAt.substr(11, 5)}
        </h3>

        <a className="enter d-flex flex-row mt-2 mb-2" href={appointment.url}>
          <h2>Enter meeting</h2>

          <svg
            width="7"
            height="13"
            viewBox="0 0 7 13"
            fill="none"
            xmlns="http://www.w3.org/2000/svg"
          >
            <path
              d="M6.99475 6.3833C6.97009 6.12764 6.86674 5.88588 6.69912 5.69131L2.21682 0.466058C2.00595 0.203107 1.69727 0.0370092 1.36154 0.00542641C1.02577 -0.0259886 0.691595 0.0798874 0.435553 0.299099C0.179346 0.518137 0.0230171 0.831755 0.0023443 1.16797C-0.0183258 1.50434 0.0984027 1.83464 0.325772 2.08351L4.11558 6.5L0.325772 10.9165C0.0983983 11.1654 -0.0183285 11.4957 0.0023443 11.832C0.0230144 12.1682 0.179346 12.4818 0.435553 12.7009C0.691586 12.9201 1.02577 13.026 1.36154 12.9946C1.6973 12.963 2.00596 12.7969 2.21682 12.5339L6.69912 7.30869C6.91902 7.05303 7.02567 6.71907 6.99475 6.38343V6.3833Z"
              fill="#18324E"
            />
          </svg>
        </a>

        <div className="urls d-flex flex-row gap-3">
          <a href={appointment.rescheduleUrl}>Reschedule</a>
          <a style={{ color: "red" }} href={appointment.cancelUrl}>
            Cancel Appointment
          </a>
        </div>
      </div>
      <div className="date col-3 px-2">
        {appointment.startedAt.substr(0, 10)}
      </div>
    </div>
  );
};

export default Appointments;
