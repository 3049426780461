import { useEffect, useState } from "react";

let userAgent = "";

const useMobile = () => {
  const [size, setSize] = useState(0);

  useEffect(() => {
    onResize();
    window.addEventListener("resize", onResize);
    return () => {
      window.removeEventListener("resize", onResize);
    };
  }, []);

  const onResize = () => {
    window && setSize(window.innerWidth);
  };
  return size ? size <= 991 : null;
};

const setIsMobile = (agent) => {
  userAgent = agent;
};

export { useMobile, setIsMobile };
