import { useAssets, useMobile } from "../../../hooks";
import { useCallback, useEffect, useState } from "react";
import { Link, useNavigate } from "react-router-dom";
import Menu, { SubMenu, Item as MenuItem } from "rc-menu";
import Drawer from "react-modern-drawer";
import "rc-menu/assets/index.css";
import "react-modern-drawer/dist/index.css";
import "./styles.scss";
import Utils from "../../../helpers/utils";
import Cookies from "js-cookie";
import Swal from "sweetalert2";
import { fetchData } from "../../../helpers/endpoints";
import FadeLoader from "react-spinners/FadeLoader";

const menuItems = [
  {
    label: "Home",
    link: "https://allbrainsclinic.com/",
  },
  {
    label: "About Us",
    children: [
      {
        label: "Our Story",
        link: "https://allbrainsclinic.com/our-philosophy",
      },
      {
        label: "Our Team",
        link: "https://allbrainsclinic.com/our-team",
      },
    ],
  },
  {
    label: "Services",
    children: [
      {
        label: "Assessment",
        link: "https://allbrainsclinic.com/services/assessment",
        children: [
          {
            label: "Comprehensive Autism Assessment",
            link:
              "https://allbrainsclinic.com/services/" +
              Utils.convertToSlug("Comprehensive Autism Assessment"),
          },
          {
            label: "In-depth Psychoeducational Assessment",
            link:
              "https://allbrainsclinic.com/services/" +
              Utils.convertToSlug("In-depth Psychoeducational Assessment"),
          },
          {
            label: "Multi-disciplinary ADHD/ADD Assessment",
            link:
              "https://allbrainsclinic.com/services/" +
              Utils.convertToSlug("Multi-disciplinary ADHD ADD Assessment"),
          },
          {
            label: "Early Language and Social Development Assessment",
            link:
              "https://allbrainsclinic.com/services/" +
              Utils.convertToSlug(
                "Early Language and Social Development Assessment"
              ),
          },
          {
            label: "General Diagnostic Assessment",
            link:
              "https://allbrainsclinic.com/services/" +
              Utils.convertToSlug("General Diagnostic Assessment"),
          },
        ],
      },
      {
        label: "Treatment",
        link:
          "https://allbrainsclinic.com/services/" +
          Utils.convertToSlug("Treatment"),

        children: [
          {
            label: "CBT (Cognitive Behavioral Therapy)",
            link:
              "https://allbrainsclinic.com/services/" +
              Utils.convertToSlug("CBT (Cognitive Behavioural Therapy)"),
          },
          {
            label: "ACT (Acceptance and Commitment Therapy)",
            link:
              "https://allbrainsclinic.com/services/" +
              Utils.convertToSlug("ACT (Acceptance and Commitment Therapy)"),
          },
          {
            label: "Parent Training",
            link:
              "https://allbrainsclinic.com/services/" +
              Utils.convertToSlug("Parent Training"),
          },
          {
            label: "Trauma Focused Therapy",
            link:
              "https://allbrainsclinic.com/services/" +
              Utils.convertToSlug("Trauma Focused Therapy"),
          },
        ],
      },
      {
        label: "Coaching",
        link:
          "https://allbrainsclinic.com/services/" +
          Utils.convertToSlug("Coaching"),
        children: [
          {
            label: "ADHD Coaching",
            link:
              "https://allbrainsclinic.com/services/" +
              Utils.convertToSlug("ADHD Coaching"),
          },
          {
            label: "Autism Coaching",
            link:
              "https://allbrainsclinic.com/services/" +
              Utils.convertToSlug("Autism Coaching"),
          },
          {
            label: "Parents Coaching",
            link:
              "https://allbrainsclinic.com/services/" +
              Utils.convertToSlug("Parents Coaching"),
          },
        ],
      },
      {
        label: "Resources",
        link: "https://allbrainsclinic.com/resources",
      },
    ],
  },
  {
    label: "Contact Us",
    link: "https://allbrainsclinic.com/contact",
  },
];

export const Header = () => {
  const { getFile } = useAssets();
  const navigate = useNavigate();
  const isMobile = useMobile();
  const [drawerOpen, setDrawerOpen] = useState(false);
  const [patients, setPatients] = useState([]);
  const [isLoading, setIsLoading] = useState(true);
  async function fetchUser() {
    var res = await fetchData("patient");
    if (res.status === 200) {
      let temp = res.data.data.filter((p) => {
        return p.clinicalCode && !("deletedAt" in p);
      });
      setPatients(temp);
    } else {
      Swal.fire({
        title: "",
        text: res,
        icon: "error",
        confirmButtonText: "ok",
      });
    }
    setIsLoading(false);
  }
  useEffect(() => {
    fetchUser();
  }, [drawerOpen, navigate]);
  const renderDesktopMenu = useCallback(() => {
    const renderItem = (item) => {
      return item.children ? (
        <SubMenu
          key={Utils.convertToSlug(item.label)}
          popupClassName="header__menu-submenu"
          title={item.label}
          onTitleClick={() => {
            if (item.link) navigate(item.link);
          }}
        >
          {item.children.map((i) => renderItem(i))}
        </SubMenu>
      ) : (
        <MenuItem
          key={Utils.convertToSlug(item.label)}
          className="header__menu-item"
          onClick={() => {
            if (item.link) navigate(item.link);
          }}
        >
          <Link to={item.link}>{item.label}</Link>
        </MenuItem>
      );
    };

    return (
      <Menu
        mode="horizontal"
        className="header__menu col-6 d-flex align-items-center"
        expandIcon={<img src={getFile("ArrowRight")} alt="" width="5" />}
      >
        {menuItems.map((item) => renderItem(item))}
        <SubMenu
          key={"profile"}
          popupClassName="header__menu-submenu"
          title={
            <img
              src={getFile("Profile")}
              style={{
                maxWidth: "30px",
              }}
            />
          }
        >
          {isLoading ? (
            <MenuItem
              key={"loading"}
              className="header__menu-item"
              onClick={() => {}}
            >
              <FadeLoader color={"#18324e"} />
            </MenuItem>
          ) : (
            patients.map((p) => {
              return (
                <MenuItem
                  key={p._id}
                  className="header__menu-item"
                  onClick={() => {
                    localStorage.setItem("id", p._id);
                    navigate("/dashboard/" + p._id + "/service");
                  }}
                >
                  {p.firstName + " " + p.lastName}
                </MenuItem>
              );
            })
          )}
          <div style={{ border: "0.5px solid #18324E" }} />
          <MenuItem
            key={"info"}
            className="header__menu-item"
            onClick={() => {
              navigate("/dashboard/0");
            }}
          >
            Patients List
          </MenuItem>
          <MenuItem
            key={"name"}
            className="header__menu-item"
            onClick={() => {
              navigate("/account");
            }}
          >
            Account Settings
          </MenuItem>
          <MenuItem
            key={"sign-out"}
            className="header__menu-item"
            onClick={() => {
              Cookies.set("Login", false);
              Cookies.set("access_token", null);
              navigate("/sign-in");
            }}
          >
            Sign Out
          </MenuItem>
        </SubMenu>
      </Menu>
    );
  }, [getFile, navigate, isLoading]);

  const renderMobileMenu = useCallback(() => {
    const renderItem = (item) => {
      return item.children ? (
        <SubMenu
          key={Utils.convertToSlug(item.label)}
          popupClassName="header__drawer-submenu"
          title={item.label}
        >
          {item.children.map((i) => (
            <MenuItem
              key={Utils.convertToSlug(i.label)}
              className="header__drawer-item"
            >
              <Link to={i.link}>{i.label}</Link>
            </MenuItem>
          ))}
        </SubMenu>
      ) : (
        <MenuItem
          key={Utils.convertToSlug(item.label)}
          className="header__drawer-item"
        >
          <Link to={item.link}>{item.label}</Link>
        </MenuItem>
      );
    };

    return (
      <Drawer
        open={drawerOpen}
        onClose={() => setDrawerOpen(!drawerOpen)}
        direction="right"
        className="header__drawer"
        overlayOpacity={0.1}
        duration="250"
      >
        {drawerOpen ? (
          <Menu mode="inline">
            {menuItems.map((item) => renderItem(item))}
            <SubMenu
              key={"profile"}
              popupClassName="header__drawer-submenu"
              title={
                <img
                  src={getFile("Profile")}
                  style={{
                    maxWidth: "30px",
                  }}
                />
              }
            >
              {isLoading ? (
                <MenuItem
                  key={"loading"}
                  className="header__drawer-item"
                  onClick={() => {}}
                >
                  <FadeLoader color={"#18324e"} />
                </MenuItem>
              ) : (
                patients.map((p) => {
                  return (
                    <MenuItem
                      key={p._id}
                      className="header__drawer-item"
                      onClick={() => {
                        if (p.clinicalCode)
                          navigate("/dashboard/" + p._id + "/service");
                        else
                          Swal.fire({
                            title: "This patient has not yet been verified.",
                            text: "After verification, access to this section is given.",
                            icon: "error",
                            confirmButtonText: "ok",
                          });
                      }}
                    >
                      {p.firstName + " " + p.lastName}
                    </MenuItem>
                  );
                })
              )}
              <div style={{ border: "0.5px solid #18324E" }} />
              <MenuItem
                key={"info"}
                className="header__drawer-item"
                onClick={() => {
                  navigate("/dashboard/0");
                }}
              >
                Patients List
              </MenuItem>
              <MenuItem
                key={"name"}
                className="header__drawer-item"
                onClick={() => {
                  navigate("/account");
                }}
              >
                Account Settings
              </MenuItem>
              <MenuItem
                key={"sign-out"}
                className="header__drawer-item"
                onClick={() => {
                  Cookies.set("Login", false);
                  Cookies.set("access_token", null);
                  navigate("/sign-in");
                }}
              >
                Sign Out
              </MenuItem>
            </SubMenu>
          </Menu>
        ) : null}
      </Drawer>
    );
  }, [drawerOpen]);

  return (
    <div className="header py-4 py-lg-3">
      <div className="container px-3">
        <div
          className="d-flex flex-row align-items-center"
          style={{ flexWrap: "nowrap" }}
        >
          <div className="header__logo col-12 col-lg-6">
            <Link to="/">
              <img src={getFile("LightLogo")} alt="logo" />
            </Link>
            {isMobile ? (
              <button onClick={() => setDrawerOpen(!drawerOpen)}>
                <img
                  src={getFile(drawerOpen ? "Close" : "Hamber")}
                  alt=""
                  width={drawerOpen ? 15 : 20}
                />
              </button>
            ) : (
              ""
            )}
          </div>
          {isMobile ? renderMobileMenu() : renderDesktopMenu()}
        </div>
      </div>
    </div>
  );
};
