import { Footer } from "./Footer";
import { Header } from "./Header";

export const Layout = ({ children }) => {
  return (
    <div
      style={{
        backgroundColor: "#ffff",
        maxWidth: "100vw",
        overflowX: "hidden !important",
      }}
    >
      <Header />
      {children}
      <Footer />
    </div>
  );
};
