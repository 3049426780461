import { Link } from "react-router-dom";
import { useMobile, useAssets } from "../../../hooks";
import "rc-menu/assets/index.css";
import "react-modern-drawer/dist/index.css";
import "./styles.scss";
import { CustomButton } from "../../CustomButton";
import { CustomInput } from "../../CustomInput";
import Utils from "../../../helpers/utils";
import Map from "../Map";

const menuItems = [
  {
    label: "Home",
    key: "home",
    link: "https://allbrainsclinic.com/",
  },
  {
    label: "About Us",
    key: "about_us",
    link: "/",
    children: [
      {
        label: "Our Story",
        key: "our_story",
        link: "https://allbrainsclinic.com/our-story",
      },
      {
        label: "Our Team",
        key: "our_team",
        link: "https://allbrainsclinic.com/our-team",
      },
    ],
  },
  {
    label: "Services",
    key: "services",
    link: "/",
    children: [
      {
        label: "Assessment",
        key: "assessment",
        link: "https://allbrainsclinic.com/services/assessment",
      },
      {
        label: "Treatment",
        key: "treatment",
        link: "https://allbrainsclinic.com/services/treatment",
      },
      {
        label: "Coaching",
        key: "coaching",
        link: "https://allbrainsclinic.com/services/coaching",
      },
      {
        label: "Resources",
        key: "resources",
        link: "https://allbrainsclinic.com/resources",
      },
    ],
  },
  {
    label: "Assessment",
    key: "assessment",
    link: "https://allbrainsclinic.com/services/assessment",
    children: [
      {
        label: "Comprehensive Autism Assessment",
        key: "Comprehensive Autism Assessment",
        link:
          "https://allbrainsclinic.com/services/" +
          Utils.convertToSlug("Comprehensive Autism Assessment"),
      },
      {
        label: "In-depth Psychoeducational Assessment",
        key: "In-depth Psychoeducational Assessment",
        link:
          "https://allbrainsclinic.com/services/" +
          Utils.convertToSlug("In-depth Psychoeducational Assessment"),
      },
      {
        label: "Multi-disciplinary ADHD/ADD Assessment",
        key: "Multi-disciplinary ADHD/ADD Assessment",
        link:
          "https://allbrainsclinic.com/services/" +
          Utils.convertToSlug("Multi-disciplinary ADHD ADD assessment"),
      },
      {
        label: "Early Language and Social Development Assessment",
        key: "Early Language and Social Development Assessment",
        link:
          "https://allbrainsclinic.com/services/" +
          Utils.convertToSlug(
            "Early Language and Social Development Assessment"
          ),
      },
      {
        label: "General Diagnostic Assessment",
        key: "General Diagnostic Assessment",
        link:
          "https://allbrainsclinic.com/services/" +
          Utils.convertToSlug("General Diagnostic Assessment"),
      },
    ],
  },
  {
    label: "Treatment",
    key: "treatment",
    link: "https://allbrainsclinic.com/services/treatment",
    children: [
      {
        label: "CBT (Cognitive behavioural therapy)",
        key: "CBT (Cognitive behavioural therapy)",
        link:
          "https://allbrainsclinic.com/services/" +
          Utils.convertToSlug("CBT (Cognitive Behavioural Therapy)"),
      },
      {
        label: "ACT (Acceptance and Commitment Therapy)",
        key: "ACT (Acceptance and Commitment Therapy)",
        link:
          "https://allbrainsclinic.com/services/" +
          Utils.convertToSlug("ACT (Acceptance and Commitment Therapy)"),
      },
      {
        label: "Parent Training",
        key: "Parent Training",
        link:
          "https://allbrainsclinic.com/services/" +
          Utils.convertToSlug("Parent Training"),
      },
      {
        label: "Trauma Focused Therapy",
        key: "Trauma Focused Therapy",
        link:
          "https://allbrainsclinic.com/services/" +
          Utils.convertToSlug("Trauma Focused Therapy"),
      },
    ],
  },
  {
    label: "Coaching",
    key: "coaching",
    link: "https://allbrainsclinic.com/services/coaching",
    children: [
      {
        label: "ADHD Coaching ",
        key: "ADHD Coaching",
        link:
          "https://allbrainsclinic.com/services/" +
          Utils.convertToSlug("ADHD Coaching"),
      },
      {
        label: "Autism Coaching",
        key: "Autism Coaching",
        link:
          "https://allbrainsclinic.com/services/" +
          Utils.convertToSlug("Autism Coaching"),
      },
      {
        label: "Parents Coaching",
        key: "Parents Coaching",
        link:
          "https://allbrainsclinic.com/services/" +
          Utils.convertToSlug("Parents Coaching"),
      },
    ],
  },
  {
    label: "Contact",
    key: "contact",
    link: "/contact-us",
  },
];
const myLocation = {
  address: "Wesbrook Village, 5933 Birney Ave, Vancouver, BC V6S 0G7، Canada",
  lat: 49.25377713585907,
  lng: -123.23565959938477,
};

export const Footer = () => {
  const isMobile = useMobile();
  const { getFile } = useAssets();

  const renderColumns = (secs) => {
    return secs.map((i) => {
      if (
        isMobile &&
        (i === "assessment" || i === "treatment" || i === "coaching")
      ) {
        return null;
      }
      const targetMenu = menuItems.find((c) => c.key === i);
      return targetMenu?.children ? (
        <div>
          <span>{targetMenu.label}</span>
          <div>
            {targetMenu.children.map((x) => (
              <Link to={x.link}>{x.label}</Link>
            ))}
          </div>
        </div>
      ) : (
        <Link to={targetMenu.link}>{targetMenu.label}</Link>
      );
    });
  };
  const socials = [
    {
      icon: getFile("Instagram"),
      url: "https://www.instagram.com/allbrainsclinic/",
    },
    {
      icon: getFile("Whatsapp"),
      url: "/",
    },
    {
      icon: getFile("Facebook"),
      url: "https://www.facebook.com/profile.php?id=100086485934368/",
    },
    {
      icon: getFile("Linkedin"),
      url: "https://www.linkedin.com/in/ali-eslami-7b9479253/",
    },
    {
      icon: getFile("Twitter"),
      url: "https://twitter.com/allbrainsclinic",
    },
  ];

  return (
    <div className="footer">
      <div className="footer__color">
        <div className="container px-4 py-4 py-lg-5">
          <div className="row mb-4">
            <div className="col-6 col-lg-4 px-0">
              <ContactColumn />
            </div>
            {!isMobile && (
              <div className="col-6 col-lg-8 ">
                <div className="container">
                  <div className="row">
                    <div className="footer__menucolumn col-6 col-lg-3">
                      {renderColumns(["home", "about_us", "services"])}
                    </div>
                    <div className="footer__menucolumn d-none d-lg-flex col-3">
                      {renderColumns(["assessment"])}
                    </div>
                    <div className="footer__menucolumn d-none d-lg-flex col-3">
                      {renderColumns(["treatment"])}
                    </div>
                    <div className="footer__menucolumn col-6 col-lg-3">
                      {renderColumns(["coaching"])}
                    </div>
                  </div>
                </div>
              </div>
            )}
            {isMobile && (
              <div className="col-6 py-5 px-0">
                <Map location={myLocation} zoomLevel={16} isFooter />
              </div>
            )}
          </div>
          <div className="container">
            <div className="row justify-content-center">
              <div className="col-8 col-lg-4">
                {isMobile && (
                  <div className="footer__contact-socials mt-1 d-flex align-items-center">
                    {socials.map((i) => (
                      <a href={i.url}>
                        <img src={i.icon} alt="" />
                      </a>
                    ))}
                  </div>
                )}
                <Subscribe />
              </div>
            </div>
          </div>
        </div>
        <Socket />
      </div>
    </div>
  );
};

const ContactColumn = () => {
  const { getFile } = useAssets();
  const isMobile = useMobile();
  const items = [
    {
      title: "Address:",
      value: [
        "5933 Birney Ave,",
        <br />,
        <span style={{ marginLeft: "-40%", lineHeight: "1.3rem" }}>
          Vancouver, BC V6S 0G7
        </span>,
      ],
      icon: getFile("Location"),
      onClick: () => {},
    },

    {
      title: "Tel:",
      value: "(604) 998-2244",
      icon: getFile("Call"),
      onClick: () => {},
    },
    {
      title: "Fax:",
      value: "(844) 927-0222",
      icon: getFile("Fax"),
      onClick: () => {},
    },
    {
      title: "",
      value: " info@allbrainsclinic.com",
      icon: getFile("Email"),
      onClick: () => {},
    },
  ];
  const socials = [
    {
      icon: getFile("Instagram"),
      url: "https://www.instagram.com/allbrainsclinic/",
    },
    {
      icon: getFile("Whatsapp"),
      url: "/",
    },
    {
      icon: getFile("Facebook"),
      url: "https://www.facebook.com/profile.php?id=100086485934368/",
    },
    {
      icon: getFile("Linkedin"),
      url: "https://www.linkedin.com/in/ali-eslami-7b9479253/",
    },
    {
      icon: getFile("Twitter"),
      url: "https://twitter.com/allbrainsclinic",
    },
  ];

  return (
    <div>
      <div className="footer__logo mb-3 mb-lg-4">
        <img src={getFile("LightLogo")} alt="" />
      </div>
      <Link to={"/contact"}>
        <span className="footer__contact-head">Contact Us:</span>
      </Link>
      {items.map((i) => (
        <div
          className="footer__contact-property d-flex align-items-start mb-3 mb-lg-4 text-white"
          key={i.title}
          onClick={i.onClick}
        >
          <img src={i.icon} alt="" />
          <div className="footer__contact-property-value">
            <span className="me-1">{i.title}</span>
            {!isMobile && <span style={{ lineHeight: "1rem" }}>{i.value}</span>}
            {isMobile && (
              <span style={{ lineHeight: "0.8rem" }}>{i.value}</span>
            )}
          </div>
        </div>
      ))}

      {!isMobile && (
        <div className="footer__contact-socials mt-4 d-flex align-items-center">
          {socials.map((i) => (
            <a href={i.url} key={i.url}>
              <img src={i.icon} alt="" />
            </a>
          ))}
        </div>
      )}
    </div>
  );
};

const Subscribe = () => {
  const handleSubmit = (e) => {
    e.preventDefault();
    alert("Submitted.");
  };
  return (
    <form
      className="d-flex flex-column align-items-center"
      onSubmit={handleSubmit}
    >
      <span className="footer__subscribe-title">
        Subscribe to get the Latest News
      </span>
      <CustomInput placeholder="Enter your Email Addresss" fullWidth />
      <CustomButton
        title={"Subscribe"}
        type="submit"
        additionalClassNames="mt-3"
      />
    </form>
  );
};

const Socket = () => {
  return (
    <div className="footer__socket d-flex flex-column gap-3">
      <span>
        Designed and Implemented by{" "}
        <a style={{ color: "#ffff" }} href="www.perfettogruppo.com">
          Perfetto Gruppo
        </a>{" "}
      </span>
      <span>© Copyright 2022 All Brains Clinic</span>
    </div>
  );
};
