import { useEffect, useState } from "react";
import {
  fetchData,
  postFormData,
  deleteData,
} from "../../../helpers/endpoints";
import "./Documents.scss";
import FadeLoader from "react-spinners/FadeLoader";
import { CustomButton } from "../../../components/CustomButton";
import Swal from "sweetalert2";
import { CustomInput } from "../../../components/CustomInput";
import { FileUploader } from "react-drag-drop-files";
import Modal from "react-modal";
import { useMobile } from "../../../hooks";

const Documents = ({ patientId }) => {
  const [documents, setDocuments] = useState([]);
  const [categoris, setCategoris] = useState([]);
  const [isLoading, setIsLoading] = useState(true);
  const isMobile = useMobile();
  const [newDoc, setNewDoc] = useState({
    title: "",
    description: "",
    file: "",
    category: {},
  });
  const [isOpen, setIsOpen] = useState(false);
  const [isOpen2, setIsOpen2] = useState(false);
  const [document, setDocument] = useState({});
  const addDocument = async () => {
    setIsLoading(true);
    let formData = new FormData();
    formData.append("title", newDoc.file.name);
    formData.append("description", newDoc.description);
    formData.append("file", newDoc.file);
    formData.append("categoryId", newDoc.category._id);
    var res = await postFormData(
      "patient/" + patientId + "/document",
      formData
    );
    if (res.status === 201) {
      fetchDocuments();
      setNewDoc({
        title: "",
        description: "",
        file: "",
        category: {},
      });
      setIsOpen(false);
    } else {
      Swal.fire({
        title: "",
        text: res,
        icon: "error",
        confirmButtonText: "ok",
      });
    }
    setIsLoading(false);
  };
  const removeDocument = async (id) => {
    var res = await deleteData("patient/" + patientId + "/document/" + id);
    if (res.status === 200) {
      fetchDocuments();
      setIsOpen2(false);
    } else {
      Swal.fire({
        title: "",
        text: res,
        icon: "error",
        confirmButtonText: "ok",
      });
      setIsOpen2(false);
    }
  };
  async function fetchDocuments() {
    setIsLoading(true);
    var res = await fetchData("patient/" + patientId + "/document");
    if (res.status === 200) {
      setDocuments(res.data.data);
    }
    var res2 = await fetchData(
      "patient/" + patientId + "/document/category/index"
    );
    if (res2.status === 200) {
      setCategoris(res2.data.data);
    } else {
      Swal.fire({
        title: "",
        text: res,
        icon: "error",
        confirmButtonText: "ok",
      });
    }
    setIsLoading(false);
  }
  useEffect(() => {
    if (patientId != 0) fetchDocuments();
  }, [patientId]);
  const customStyles = {
    overlay: {
      backgroundColor: "rgba(24, 50, 78, 0.9)",
      zInsex: 5,
    },
  };
  return (
    <>
      <div dir="ltr" className="documents d-flex flex-column gap-5 py-4 px-3">
        <div className="documents d-flex flex-column gap-2">
          <h1>Documents</h1>
          <p>
            If you have scanned copies of divese you may attach and upload divem
            using divis form. Alternatively you may fax divese to us at
            416-482-8999.
          </p>
        </div>

        {isLoading ? (
          <FadeLoader color={"#18324e"} className="mt-3" />
        ) : (
          <table className="w-100 ">
            <thead className="w-100">
              <th className={!isMobile ? "col-3" : "col-2"}>Title</th>
              <th className="col-2">Description</th>
              <th className="col-2">File Name</th>
              {!isMobile && <th className="col-2">Last Modified</th>}
              <th className="col-1" />
              <th className="col-2" />
            </thead>
            <tbody>
              {categoris.map((c) => {
                let temp = documents.filter((d) => {
                  return d.categoryId == c._id;
                });
                if (temp.length > 0) {
                  return Document(
                    temp,
                    c,
                    setIsOpen,
                    setNewDoc,
                    newDoc,
                    setDocument,
                    setIsOpen2,
                    isMobile
                  );
                } else {
                  return (
                    <tr className="last">
                      <td className="category">{c.title}</td>
                      <td />
                      <td />
                      {!isMobile && <td />}
                      <td />

                      <td>
                        <CustomButton
                          title={"Upload New"}
                          onClick={() => {
                            setIsOpen(true);
                            setNewDoc({ ...newDoc, category: c });
                          }}
                        />
                      </td>
                    </tr>
                  );
                }
              })}
            </tbody>
          </table>
        )}
      </div>
      <Modal
        isOpen={isOpen}
        onRequestClose={() => setIsOpen(false)}
        style={customStyles}
        contentLabel="Example Modal"
        className="myModal"
      >
        <div className="d-flex flex-column justify-content-center gap-2 px-3">
          <h1>Upload Documents</h1>
          <p>You can attach a PDF,DOCX ,MP4 ,MOV ,JPG or PNG file.</p>
          <div className="line" />
          <h2
            style={{
              fontSize: "18px",
              fontWeight: "700",
              color: "#18324e",
              marginBottom: "25px",
              marginTop: "-15px",
            }}
          >
            {newDoc.category.title}
          </h2>
          <CustomInput
            label={"Description"}
            fullWidth
            onChange={(e) => setNewDoc({ ...newDoc, description: e })}
          />
          <div className="mx-2">
            <FileUploader
              required
              handleChange={(file) => setNewDoc({ ...newDoc, file: file })}
              name="file"
              types={["JPG", "PNG", "PDF", "mp4", "mov", "document", "docx"]}
              maxSize={50}
            />
          </div>
          <CustomButton
            uiType="primary2"
            title={"Done"}
            additionalClassNames="col-4 mx-2"
            onClick={addDocument}
            loading={isLoading}
          />
        </div>
      </Modal>
      <Modal
        isOpen={isOpen2}
        onRequestClose={() => {
          setIsOpen2(false);
        }}
        style={customStyles}
        contentLabel="Example Modal"
        className="Modal1"
      >
        <div className=" d-flex flex-column justify-content-center align-items-center gap-5 pt-5 ">
          <h2>Are you sure to delete this document?</h2>
          <div className="d-flex flex-row justify-content-center align-items-center gap-3">
            <CustomButton
              title={"No"}
              uiType={"outlined2"}
              onClick={() => {
                setIsOpen2(false);
              }}
            />
            <CustomButton
              title={"Yes"}
              uiType="primary2"
              onClick={() => removeDocument(document._id)}
            />
          </div>
        </div>
      </Modal>
    </>
  );
};

const Document = (
  documents,
  category,
  setIsOpen,
  setNewDoc,
  newDoc,
  setDocument,
  setIsOpen2,
  isMobile
) => {
  return documents.map((d, i) => {
    return (
      <tr className={i === documents.length - 1 ? "last" : ""}>
        <td className="category">{i === 0 ? category.title : ""}</td>
        <td>{d.description}</td>
        <td
          style={
            !isMobile
              ? { fontSize: "13px", cursor: "pointer" }
              : { fontSize: "8px", cursor: "pointer" }
          }
          className="d-flex flex-row "
        >
          <a style={{ marginRight: "5px" }} href={d.url} target="_blank">
            {documentIcons(d.type)}
          </a>

          <h6 style={{ alignSelf: "center" }}>{d.title}</h6>
        </td>
        {!isMobile && (
          <td style={{ fontSize: "12px" }}>{d.updatedAt.substr(0, 10)}</td>
        )}
        <td
          style={{ cursor: "pointer" }}
          className="d-flex flex-row"
          onClick={() => {
            setDocument(d);
            setIsOpen2(true);
          }}
        >
          <h2 style={{ fontWeight: "500", color: "red" }}>Remove</h2>
        </td>
        <td>
          {i === 0 ? (
            <CustomButton
              title={"Upload New"}
              onClick={() => {
                setIsOpen(true);
                setNewDoc({ ...newDoc, category: category });
              }}
            />
          ) : (
            ""
          )}
        </td>
      </tr>
    );
  });
};

const MobileDocument = (
  documents,
  category,
  setIsOpen,
  setNewDoc,
  newDoc,
  setDocument,
  setIsOpen2
) => {
  return documents.map((d, i) => {
    return (
      <tr className={i === documents.length - 1 ? "last" : ""}>
        <td className="category">{i === 0 ? d.category : ""}</td>
        <td>{d.description}</td>
        <td style={{ fontSize: "13px", cursor: "pointer" }}>
          <a style={{ marginRight: "5px" }} href={d.url} target="_blank">
            {documentIcons(d.type)}
          </a>

          {d.title}
        </td>
        <td style={{ fontSize: "12px" }}>{d.updatedAt.substr(0, 10)}</td>
        <td
          style={{ cursor: "pointer" }}
          className="d-flex flex-row"
          onClick={() => {
            setDocument(d);
            setIsOpen2(true);
          }}
        >
          <h2 style={{ fontWeight: "500", color: "red" }}>Remove</h2>
        </td>
        <td>
          {i === 0 ? (
            <CustomButton
              title={"Upload New"}
              onClick={() => {
                setIsOpen(true);
                setNewDoc({ ...newDoc, category: category });
              }}
            />
          ) : (
            ""
          )}
        </td>
      </tr>
    );
  });
};

const documentIcons = (type) => {
  if (type.includes("image")) {
    return (
      <svg
        width="32"
        height="32"
        viewBox="0 0 32 32"
        fill="none"
        xmlns="http://www.w3.org/2000/svg"
      >
        <g opacity="0.5">
          <path
            d="M22.1416 9.22943C21.6376 8.55581 20.6927 8.55581 20.2203 9.22943L14.5196 16.9431C14.3937 17.1116 14.1731 17.1116 14.0472 16.9767L11.8109 14.3829C11.3069 13.7768 10.425 13.7768 9.92105 14.3829L7.40132 17.3134C7.14933 17.6165 7.02344 17.987 7.02344 18.3914V28.1935C7.02344 28.564 7.30685 28.8335 7.62192 28.8335H24.4722C24.8187 28.8335 25.0707 28.5304 25.0707 28.1935V13.6756C25.0707 13.3051 24.9448 12.9682 24.7557 12.6989L22.1416 9.22943Z"
            fill="#18324E"
          />
          <path
            d="M24.7874 0H7.21264C5.44892 0 4 1.54959 4 3.43584V28.5642C4 30.4504 5.44892 32 7.21264 32H24.7874C26.5511 32 28 30.4504 28 28.5642V3.43584C28 1.54959 26.5513 0 24.7874 0ZM26.2048 28.5641C26.2048 29.4062 25.575 30.0798 24.7876 30.0798H7.21286C6.42547 30.0798 5.7956 29.4062 5.7956 28.5641V3.43578C5.7956 2.59367 6.42547 1.92005 7.21286 1.92005H24.7876C25.575 1.92005 26.2048 2.59367 26.2048 3.43578V28.5641Z"
            fill="#18324E"
          />
          <path
            d="M12.1877 6.60207C12.1877 7.79262 11.2854 8.75785 10.172 8.75785C9.05878 8.75785 8.15625 7.79262 8.15625 6.60207C8.15625 5.41128 9.05878 4.44629 10.172 4.44629C11.2854 4.44629 12.1877 5.41128 12.1877 6.60207Z"
            fill="#18324E"
          />
        </g>
      </svg>
    );
  } else if (type.includes("pdf")) {
    return (
      <svg
        width="32"
        height="32"
        viewBox="0 0 32 32"
        fill="none"
        xmlns="http://www.w3.org/2000/svg"
      >
        <g opacity="0.5" clip-path="url(#clip0_3416_117834)">
          <path
            d="M23.068 6.69438H28.5259C28.4242 6.52483 28.2885 6.35528 28.1192 6.18597L22.7291 0.795647C22.5936 0.660149 22.4241 0.524414 22.2207 0.388916V5.81308C22.2205 6.32149 22.5934 6.69443 23.068 6.69443L23.068 6.69438Z"
            fill="#18324E"
          />
          <path
            d="M15.1332 11.9149C15.0994 11.847 15.0315 11.7794 14.8281 11.7794C14.6248 11.7794 14.5907 11.8472 14.5907 11.881C14.3873 12.3556 14.6245 13.4065 15.0991 14.5253C15.4044 13.1015 15.3706 12.2539 15.1332 11.9149L15.1332 11.9149Z"
            fill="#18324E"
          />
          <path
            d="M23.067 8.96568C21.3382 8.96568 19.9482 7.57571 19.9482 5.84686V-0.0856934H6.01517C5.2015 -0.0856934 4.45564 0.219355 3.87939 0.795653C3.30312 1.37193 2.99805 2.11776 2.99805 2.93143V29.0685C2.99805 30.7297 4.35394 32.0857 6.01517 32.0857H25.9824C27.6436 32.0857 28.9996 30.7298 28.9996 29.0685V8.96568H23.067ZM23.1687 21.4749C22.6941 21.9495 22.05 22.2207 21.4058 22.2207C20.4566 22.2207 19.3379 21.6782 18.0837 20.6612C17.4057 20.7967 16.6598 21.0003 15.7786 21.2374C15.0328 21.4408 14.3886 21.6782 13.8123 21.8816C12.7953 23.7121 11.4732 25.5767 10.219 25.8817C10.0835 25.9155 9.94774 25.9496 9.81224 25.9496C9.47314 25.9496 9.16807 25.8479 8.89687 25.6105C8.4561 25.2376 8.25271 24.7291 8.35441 24.1867C8.62565 22.7967 10.8631 21.6103 12.897 20.7966C13.1682 20.2882 13.4054 19.7458 13.6428 19.2033C14.0495 18.1863 14.3886 17.2709 14.6258 16.4912C13.6766 14.7284 12.8969 12.5587 13.4392 11.3724C13.7104 10.7961 14.2188 10.457 14.8967 10.457C15.5068 10.457 15.9817 10.6944 16.2867 11.169C16.8968 12.0504 16.7951 13.7794 16.0493 16.3558C16.3884 16.932 16.7613 17.4745 17.1001 17.8474C17.5747 18.3558 18.0494 18.8304 18.4901 19.2033C21.2022 18.7287 22.9989 18.9321 23.5413 19.8134C23.6094 20.017 23.9145 20.6952 23.1687 21.4748L23.1687 21.4749Z"
            fill="#18324E"
          />
          <path
            d="M15.4745 17.9831C15.2711 18.5256 15.0678 19.1018 14.8303 19.712C14.7625 19.8815 14.6948 20.017 14.627 20.1866C14.932 20.0849 15.1694 20.017 15.4066 19.9492C15.915 19.8137 16.4237 19.6779 16.8642 19.5424C16.593 19.305 16.3558 19.034 16.0845 18.7288C15.915 18.5594 15.7116 18.2882 15.4745 17.9832L15.4745 17.9831Z"
            fill="#18324E"
          />
          <path
            d="M9.60938 24.458C9.71106 24.6275 9.74488 24.6275 9.8468 24.6275C10.3214 24.492 10.9993 23.8139 11.6773 22.797C10.3214 23.4747 9.67724 24.1189 9.60938 24.458L9.60938 24.458Z"
            fill="#18324E"
          />
          <path
            d="M19.9122 20.3223C20.4884 20.6614 20.9971 20.8648 21.4376 20.8648C21.8105 20.8986 22.0818 20.7969 22.2513 20.5257C22.4206 20.2544 21.5393 20.1528 19.9121 20.3223L19.9122 20.3223Z"
            fill="#18324E"
          />
        </g>
        <defs>
          <clipPath id="clip0_3416_117834">
            <rect width="32" height="32" fill="white" />
          </clipPath>
        </defs>
      </svg>
    );
  } else if (type.includes("document")) {
    return (
      <svg
        width="32"
        height="32"
        viewBox="0 0 32 32"
        fill="none"
        xmlns="http://www.w3.org/2000/svg"
      >
        <g opacity="0.5" clip-path="url(#clip0_3480_118436)">
          <path
            d="M4.95894 8.85147e-06C4.70567 -0.0011072 4.46221 0.103357 4.28248 0.290184C4.10255 0.477012 4.00108 0.730804 4.00001 0.99618V30.996C3.99915 31.2627 4.09977 31.5185 4.27992 31.7071C4.45984 31.8955 4.70436 32.0011 4.95889 32H27.0411C27.2957 32.0011 27.5402 31.8955 27.7201 31.7071C27.9002 31.5185 28.0008 31.2627 28 30.996V10.1956H19.2161C18.6913 10.1935 18.2646 9.74712 18.263 9.19756V0.000471706L4.95894 8.85147e-06ZM20.1745 0.591719V8.19339H27.4363L20.1745 0.591719ZM8.69734 13.9665C8.72953 13.9649 8.76193 13.9649 8.79433 13.9665H23.2137C23.4708 13.9605 23.7191 14.0634 23.9029 14.2515C24.0867 14.4397 24.1903 14.6975 24.1903 14.9665C24.1903 15.2357 24.0867 15.4935 23.9029 15.6817C23.7191 15.8699 23.4708 15.9725 23.2137 15.9665H8.79433C8.45814 15.9759 8.142 15.7998 7.96163 15.5027C7.78127 15.2056 7.76294 14.831 7.91366 14.5163C8.06438 14.2016 8.36202 13.9928 8.69734 13.9665ZM8.69926 19.1325C8.73081 19.1314 8.76258 19.1321 8.79434 19.1345H23.2137C23.4708 19.1285 23.7191 19.2312 23.9029 19.4193C24.0867 19.6075 24.1903 19.8653 24.1903 20.1345C24.1903 20.4035 24.0867 20.6613 23.9029 20.8495C23.7191 21.0377 23.4708 21.1404 23.2137 21.1346H8.79434C8.45814 21.1439 8.142 20.9678 7.96163 20.6707C7.78106 20.3736 7.76294 19.9991 7.91366 19.6843C8.06438 19.3696 8.36199 19.1609 8.69731 19.1346C8.69795 19.1339 8.69862 19.1332 8.69926 19.1325ZM8.69734 24.3003C8.72953 24.2988 8.76193 24.2988 8.79433 24.3003H23.2137C23.4708 24.2945 23.7191 24.3972 23.9029 24.5854C24.0867 24.7735 24.1903 25.0313 24.1903 25.3003C24.1903 25.5695 24.0867 25.8273 23.9029 26.0155C23.7191 26.2037 23.4708 26.3064 23.2137 26.3004H8.79433C8.45814 26.3097 8.142 26.1336 7.96163 25.8365C7.78127 25.5394 7.76294 25.1651 7.91366 24.8503C8.06438 24.5356 8.36202 24.3267 8.69734 24.3003Z"
            fill="#18324E"
          />
        </g>
        <defs>
          <clipPath id="clip0_3480_118436">
            <rect width="32" height="32" fill="white" />
          </clipPath>
        </defs>
      </svg>
    );
  } else if (type.includes("video")) {
    return (
      <svg
        width="32"
        height="32"
        viewBox="0 0 32 32"
        fill="none"
        xmlns="http://www.w3.org/2000/svg"
      >
        <g opacity="0.5">
          <path
            d="M22.6689 6.00892H27.5773C27.5253 5.94194 27.4764 5.87326 27.4155 5.81232L22.1954 0.58691C22.1359 0.527423 22.068 0.479059 22.002 0.427795V5.34151C22.0022 5.70956 22.3013 6.00892 22.6689 6.00892Z"
            fill="#18324E"
          />
          <path
            d="M16.0019 13.7947C12.6934 13.7947 10.002 16.4864 10.002 19.7952C10.002 23.1037 12.6934 25.7952 16.0019 25.7952C19.3104 25.7952 22.0019 23.1037 22.0019 19.7952C22.0019 16.4867 19.3104 13.7947 16.0019 13.7947ZM18.1451 20.6278L14.7636 22.5179C14.6236 22.596 14.4705 22.6351 14.3177 22.6351C14.1588 22.6351 13.9999 22.5928 13.856 22.5082C13.5644 22.337 13.3828 22.0146 13.3828 21.6671V17.8917C13.3828 17.556 13.5586 17.2443 13.841 17.0784C14.1165 16.9174 14.4451 16.9137 14.7239 17.069L18.1459 18.9815C18.4394 19.1462 18.621 19.462 18.621 19.8056C18.6203 20.15 18.4382 20.4644 18.1451 20.6278Z"
            fill="#18324E"
          />
          <path
            d="M22.6667 7.34424C21.5638 7.34424 20.6667 6.44612 20.6667 5.34223V0H6.00009C4.90004 0 4 0.901029 4 2.00201V29.998C4 31.1058 4.90004 32 6.00009 32H26.0004C27.1005 32 28.0005 31.1058 28.0005 29.998V7.34381L22.6667 7.34424ZM16.0002 27.1285C11.9565 27.1285 8.6669 23.8388 8.6669 19.7952C8.6669 15.7513 11.9566 12.4613 16.0002 12.4613C20.0438 12.4613 23.3335 15.751 23.3335 19.7952C23.3335 23.8389 20.0438 27.1285 16.0002 27.1285Z"
            fill="#18324E"
          />
        </g>
      </svg>
    );
  }
};

export default Documents;
