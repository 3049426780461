import { useEffect, useState } from "react";
import "./styles.scss";
import { useLocation } from "react-router-dom";
import Carousel from "react-multi-carousel";
import "react-multi-carousel/lib/styles.css";
import ServiceCard from "./ServiceCard";
import ServicePage from "./ServicePage";
import { fetchData } from "../../../../helpers/endpoints";
import FadeLoader from "react-spinners/FadeLoader";

const Services = ({ patientId, mrn, email, name, serviceId }) => {
  const pathname = useLocation().pathname;
  const [active, setActive] = useState(1);
  const [services, setServices] = useState([]);
  const [userServices, setUserServices] = useState([]);
  const [userComplateServices, setComplateUserServices] = useState([]);
  const [current, setCurrent] = useState();
  const [isOpen, setIsOpen] = useState(false);
  const [loading, setLoading] = useState(false);
  const [isPurchase, setIsPurchase] = useState(false);

  const fetchServices = async () => {
    setLoading(true);
    var res = await fetchData(`patient/${patientId}/service`);
    if (res.status == 200) {
      setServices(
        res.data.data[0].services.filter((s) => {
          return !("deletedAt" in s) && "publishedAt" in s;
        })
      );
    }
    var res2 = await fetchData(`patient/${patientId}/service/purchase/index`);
    if (res2.status == 200) {
      var temp1 = [];
      var temp2 = [];
      res2.data.data.map((s) => {
        if (s.status === "completed") {
          temp1 = [...temp1, s];
        } else temp2 = [...temp2, s];
      });
      setComplateUserServices(temp1);
      setUserServices(temp2);
    }

    setLoading(false);
  };
  const fetchService = async () => {
    setLoading(true);
    var domain = pathname.includes("purchase")
      ? `patient/${patientId}/service/purchase/${serviceId}`
      : `patient/${patientId}/service/${serviceId}`;
    var res = await fetchData(domain);
    if (res.status == 200) {
      if (pathname.includes("purchase"))
        setCurrent({
          _id: serviceId,
          id: serviceId,
          description: res.data.data.description,
          service: res.data.data.service,
        });
      else {
        setCurrent(res.data.data);
      }
      setIsPurchase(pathname.includes("purchase"));
      setIsOpen(true);
    } else {
      await fetchServices();
    }

    setLoading(false);
  };

  useEffect(() => {
    if (serviceId) {
      fetchService();
    } else {
      fetchServices();
      setIsOpen(false);
    }
  }, [patientId]);

  return (
    <div dir="ltr" className="d-flex flex-column w-100 py-5 gap-4">
      {isOpen ? (
        <ServicePage
          current={current}
          setIsOpen={setIsOpen}
          patientId={patientId}
          isPurchase={isPurchase}
          name={name}
          mrn={mrn}
          email={email}
          fetchServices={fetchServices}
          setIsPurchase={setIsPurchase}
        />
      ) : serviceId ? (
        <FadeLoader color={"#18324e"} />
      ) : (
        <>
          {" "}
          <div className="services1 d-flex flex-row w-50 gap-4 ">
            <button
              className={active == 0 ? "active" : ""}
              onClick={() => {
                setActive(0);
              }}
            >
              All
            </button>
            <button
              className={active == 1 ? "active" : ""}
              onClick={() => {
                setActive(1);
              }}
            >
              In progress
            </button>
            <button
              className={active == 2 ? "active" : ""}
              onClick={() => {
                setActive(2);
              }}
            >
              Completed
            </button>
          </div>
          <div>
            {active == 0 ? (
              <Home
                services={services}
                setCurrent={setCurrent}
                setIsOpen={setIsOpen}
                loading={loading}
                setIsPurchase={setIsPurchase}
              />
            ) : active == 1 ? (
              <InProgress
                services={userServices}
                setCurrent={setCurrent}
                setIsOpen={setIsOpen}
                loading={loading}
                setIsPurchase={setIsPurchase}
              />
            ) : (
              <Completed
                services={userComplateServices}
                setCurrent={setCurrent}
                setIsOpen={setIsOpen}
                loading={loading}
                setIsPurchase={setIsPurchase}
              />
            )}
          </div>
        </>
      )}
    </div>
  );
};
const responsive = {
  desktop: {
    breakpoint: { max: 3000, min: 991 },
    items: 3,
    slidesToSlide: 2, // optional, default to 1.
  },
  tablet: {
    breakpoint: { max: 991, min: 464 },
    items: 2,
    slidesToSlide: 2, // optional, default to 1.
  },
  mobile: {
    breakpoint: { max: 464, min: 0 },
    items: 1,
    slidesToSlide: 1, // optional, default to 1.
  },
};
const Home = ({ services, setCurrent, setIsOpen, loading, setIsPurchase }) => {
  return (
    <div>
      <div className="d-flex flex-column gap-4 py-4">
        <h2 className="title">All Services</h2>
        {loading ? (
          <FadeLoader color={"#18324e"} />
        ) : (
          <Carousel
            swipeable={true}
            showDots={true}
            responsive={responsive}
            ssr={true}
            infinite={true}
            autoPlay={true}
            autoPlaySpeed={3000}
            keyBoardControl={true}
            customTransition="all .5"
            transitionDuration={500}
            dotListClass="custom-dot-list-style"
            itemClass="px-3"
            renderDotsOutside={true}
          >
            {services.map((s) => {
              return (
                <ServiceCard
                  service={s}
                  buttonType={"More"}
                  setCurrent={setCurrent}
                  setIsOpen={setIsOpen}
                  isPurchase={false}
                  setIsPurchase={setIsPurchase}
                />
              );
            })}
          </Carousel>
        )}
      </div>
      <div className="d-flex flex-column gap-4 py-4">
        <h2 className="title">It May Work For You</h2>
        {loading ? (
          <FadeLoader color={"#18324e"} />
        ) : (
          <Carousel
            swipeable={true}
            rewindWithAnimation={true}
            showDots={true}
            responsive={responsive}
            ssr={true}
            infinite={true}
            autoPlay={true}
            autoPlaySpeed={3000}
            keyBoardControl={true}
            customTransition="all .5"
            transitionDuration={500}
            dotListClass="custom-dot-list-style"
            itemClass="px-3"
            renderDotsOutside={true}
          >
            {services.map((s) => {
              return (
                <ServiceCard
                  service={s}
                  buttonType={"More"}
                  setCurrent={setCurrent}
                  setIsOpen={setIsOpen}
                  isPurchase={false}
                  setIsPurchase={setIsPurchase}
                />
              );
            })}
          </Carousel>
        )}
      </div>
    </div>
  );
};
const InProgress = ({
  services,
  setCurrent,
  setIsOpen,
  loading,
  setIsPurchase,
}) => {
  return (
    <div className="d-flex flex-column gap-4 py-4">
      <h2 className="title">In progress Services</h2>
      {loading ? (
        <FadeLoader color={"#18324e"} />
      ) : (
        <Carousel
          swipeable={true}
          rewindWithAnimation={true}
          renderDotsOutside={true}
          showDots={true}
          responsive={responsive}
          ssr={true}
          infinite={true}
          autoPlay={true}
          autoPlaySpeed={3000}
          keyBoardControl={true}
          customTransition="all .5"
          transitionDuration={500}
          dotListClass="custom-dot-list-style"
          itemClass="px-3"
        >
          {services.map((s) => {
            return (
              <ServiceCard
                service={s}
                buttonType={"Continue"}
                setCurrent={setCurrent}
                setIsOpen={setIsOpen}
                isPurchase={true}
                setIsPurchase={setIsPurchase}
              />
            );
          })}
        </Carousel>
      )}
    </div>
  );
};
const Completed = ({
  services,
  setCurrent,
  setIsOpen,
  loading,
  setIsPurchase,
}) => {
  return (
    <div className="d-flex flex-column gap-4 py-4">
      <h2 className="title">Completed Services</h2>
      {loading ? (
        <FadeLoader color={"#18324e"} />
      ) : (
        <Carousel
          swipeable={true}
          renderDotsOutside={true}
          rewindWithAnimation={true}
          showDots={true}
          responsive={responsive}
          ssr={true}
          infinite={true}
          autoPlay={true}
          autoPlaySpeed={3000}
          keyBoardControl={true}
          customTransition="all .5"
          transitionDuration={500}
          dotListClass="custom-dot-list-style"
          itemClass="px-3"
        >
          {services.map((s) => {
            return (
              <ServiceCard
                service={s}
                buttonType={"Readd"}
                setCurrent={setCurrent}
                setIsOpen={setIsOpen}
                isPurchase={true}
                setIsPurchase={setIsPurchase}
              />
            );
          })}
        </Carousel>
      )}
    </div>
  );
};
export default Services;
